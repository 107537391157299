import { notification } from 'antd';
import { query } from '../services/adminUser';

export default {
  namespace: 'adminUser',

  state: {
    admin_user: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const { admin_user: adminUser } = response;
      yield put({
        type: 'updateAdminUser',
        payload: {
          admin_user: adminUser,
        },
      });
    },
  },

  reducers: {
    updateAdminUser(state, { payload }) {
      const { admin_user: adminUser } = payload;
      return {
        ...state,
        admin_user: adminUser,
      };
    },
  },
};

