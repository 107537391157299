import _ from 'lodash';
import { notification } from 'antd';
import { fetchCorporationDetail, queryCompanies } from '../services/corporation';

export default {
  namespace: 'corporation',

  state: {
    companyList: {},
    detail: {},
  },

  effects: {
    *fetchCorporationDetail({ payload }, { call, put }) {
      const response = yield call(fetchCorporationDetail, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'fetchCorporationDetailSuccess',
        payload: { detail: response.corporation },
      });
    },
    *fetchCompanies({ payload }, { call, put }) {
      const { ...params } = payload;

      const response = yield call(queryCompanies, params);
      if (response.error) throw response.error;
      const companies = [];
      for (const company of Object.values(response.companies)) {
        companies.push({
          key: company.id,
          number: company.number,
          name: company.name,
          intro: company.introduction,
          nickname: company.nickname,
          logo: company.logo.url,
          status: company.status,
          trial: company.trial,
          platform: company.platform,
          trial_days: company.trial_days,
          trial_remaining_days: company.trial_remaining_days,
          company_type: company.company_type,
        });
      }
      yield put({
        type: 'fetchCompaniesSuccess',
        payload: {
          companyList: {
            list: companies,
            pagination: {
              total: response.all_count,
              current: response.current_page,
              pageSize: payload.per_page,
            },
          },
        },
      });
    },

  },

  reducers: {
    fetchCorporationDetailSuccess(state, { payload }) {
      const { detail } = payload;
      return {
        ...state,
        detail,
      };
    },

    fetchCompaniesSuccess(state, action) {
      const { companyList } = action.payload;
      return {
        ...state,
        companyList,
      };
    },

    fetchAvailableCompaniesSuccess(state, action) {
      const { companies } = action;
      return {
        ...state,
        availableCompany: companies,
      };
    },
  },

  subscriptions: {},
};
