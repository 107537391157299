import _ from 'lodash';
import request from '../utils/request';

export async function fetchCorporationDetail(params) {
  const { id } = params;
  return request('GET', `/admin/corporations/${id}`);
}

export async function queryAll(params) {
  return request('GET', '/admin/corporations', params);
}

export async function addCorporation(params) {
  const { name, introduction, logo, nickname, status, bannerImages, number } = params;
  const data = new FormData();
  if (name) data.set('corporation[name]', name);
  if (introduction) data.set('corporation[introduction]', introduction);
  if (nickname) data.set('corporation[nickname]', nickname);
  if (logo) data.set('corporation[logo]', logo);
  if (status) data.set('corporation[status]', status);
  if (bannerImages && bannerImages.length > 0) {
    bannerImages.forEach((image) => {
      data.append('corporation[banner_images][]', image);
    });
  }
  data.append('corporation[number]', number);
  return request(
    'POST',
    '/admin/corporations',
    data,
  );
}

export async function updateCorporation(params) {
  const { id, name, introduction, logo, nickname, status, bannerImages, number } = params;
  const data = new FormData();
  if (name) data.set('corporation[name]', name);
  if (introduction) data.set('corporation[introduction]', introduction);
  if (nickname) data.set('corporation[nickname]', nickname);
  if (logo) data.set('corporation[logo]', logo);
  if (status) data.set('corporation[status]', status);
  if (bannerImages && bannerImages.length > 0) {
    bannerImages.forEach((image) => {
      data.append('corporation[banner_images][]', image);
    });
  }
  Object.keys(params).filter(k => k.startsWith('field_value')).forEach((k) => {
    const fieldId = k.split('-').pop();
    data.append('field_values[][field_id]', fieldId);
    data.append('field_values[][value]', params[k] || '');
  });
  data.append('corporation[number]', number);
  return request(
    'PUT',
    `/admin/corporations/${id}`,
    data,
  );
}

export async function queryCompanies(params) {
  const { id } = params;
  return request('GET', `/admin/corporations/${id}/companies`, {
    page: params.page,
    per_page: params.per_page,
  });
}


export async function addCompaniesToCorporation(payload) {
  const { id } = payload;
  return request('PUT',
    '/admin/corporations/:corporation_id/companies'.replace(':corporation_id', id),
    { company_ids: payload.company_ids },
  );
}
