import * as Sentry from '@sentry/react';
import { queryCurrent } from '../services/adminUser';

export default {
  namespace: 'user',

  state: {
    currentUser: {},
  },

  effects: {
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      const { user = {} } = response;
      const { name, email } = user;
      if (process.env.DEPLOY_ENV === 'production') Sentry.setUser({ email });
      // this is User#id
      window.localStorage.setItem('WS_USER_ID', user.user_id);
      window.localStorage.setItem('WS_USER_EMAIL', email);

      if (window.location.host === 'admin.workstream.is') {
        window.heap.addUserProperties({ username: name });
        window.heap.addUserProperties({ email });
      }

      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
      // this is actuall AdminUser#id
      const { id: userId } = user;
      yield put({
        type: 'asyncTasks/query',
        payload: { user_id: userId },
      });
      yield put({ type: 'webSocket/subscriptions' });
    },
  },

  reducers: {
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload.user,
      };
    },
  },
};
