import * as services from '../services/jobPostings';
import constants from '../utils/constants';

export const getRequestOverallStatus = (request) => {
  const { approved, pending, rejected, flagged, parent_not_approved: parentNotApproved } = constants.postingReviewStatus;
  const { latest_status: latestStatus, parent_requests: parentRequests } = request;
  if (latestStatus === constants.postingReviewStatus.pending) return pending;
  if (latestStatus === constants.postingReviewStatus.rejected) return rejected;
  if (latestStatus === constants.postingReviewStatus.flagged) return flagged;
  if (latestStatus === approved && !parentRequests) return approved;
  if (latestStatus === approved &&
    (
      (!parentRequests.company && !parentRequests.location) ||
      (!parentRequests.company && parentRequests.location && parentRequests.location.latest_status === approved) ||
      (parentRequests.location && parentRequests.company && parentRequests.company.latest_status === approved)
    )
  ) {
    return approved;
  } else {
    return parentNotApproved;
  }
};

export default {
  namespace: 'jobPostings',
  state: {
    list: [],
    pagination: { page: 0, pageSize: 10 },
    currentRequest: {},
  },

  effects: {
    *fetchReviewRequests({ payload }, { call, put }) {
      const res = yield call(services.fetchReviewRequests, payload);
      if (res.error) throw res.error;
      // const res = { ...indexData };
      yield put({
        type: 'fetchReviewRequestsSuccess',
        payload: {
          list: res.publish_review_requests,
          pagination: {
            total: res.all_count,
            current: res.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },

    *fetchRequestDetail({ payload }, { call, put }) {
      const response = yield call(services.fetchRequestDetail, payload);
      yield put({
        type: 'fetchReviewRequestSuccess',
        payload: {
          currentRequest: response.publish_review_request,
        },
      });
      return response.publish_review_request;
    },

    *fetchPastReviewRequests({ payload }, { call }) {
      const response = yield call(services.fetchPastReviewRequests, payload);
      if (response.error) throw response.error;
      return response.publish_review_requests;
    },

    *fetchPastRequestDetail({ payload }, { call, put }) {
      const response = yield call(services.fetchRequestDetail, payload);
      return response.publish_review_request;
    },

    *createReviewHistory({ payload }, { call }) {
      const response = yield call(services.createReviewHistory, payload);
      if (response.error) {
        if (response.error.errorCode === constants.errorCode.reviewRequestReplaced ||
          response.error.errorCode === constants.errorCode.reviewRequestUpdated
        ) {
          return response;
        } else {
          throw response.error;
        }
      }
      return response;
    },
  },

  reducers: {
    fetchReviewRequestsSuccess(state, { payload }) {
      const { list, pagination } = payload;
      return {
        ...state,
        list,
        pagination,
      };
    },
    fetchReviewRequestSuccess(state, { payload }) {
      const { currentRequest } = payload;
      return {
        ...state,
        currentRequest,
      };
    },
  },
};
