import { notification } from 'antd';
import * as services from '../services/accountTemplates';

export default {
  namespace: 'accountTemplates',
  state: {
    list: [],
    pagination: {},
    templates: [],
    locationsOfCompany: [],
  },

  effects: {
    *fetchPoddingCodes({ payload }, { call, put }) {
      const res = yield call(services.fetchPoddingCodes, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchPoddingCodesSuccess',
        payload: {
          list: res.account_templates,
          pagination: {
            total: res.all_count,
            current: res.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },

    *createPoddingCode({ payload }, { call, put }) {
      const res = yield call(services.createPoddingCode, payload);
      if (res.error) throw res.error;
    },

    *editPoddingCode({ payload }, { call, put }) {
      const res = yield call(services.editPoddingCode, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'editPoddingCodeSuccess',
        payload: { code: res.account_template },
      });
    },

    *fetchTemplates({ payload }, { call, put }) {
      const res = yield call(services.fetchTemplateCompanies);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchTemplatesSuccess',
        payload: { templates: res.companies },
      });
      return res;
    },

    *fetchLocationsOfCompany({ payload }, { call, put }) {
      const res = yield call(services.fetchLocationsOfCompany, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchLocationsSuccess',
        payload: { locations: res.locations },
      });
      return res;
    },

    *createTemplateFromActiveCompany({ payload }, { call }) {
      const res = yield call(services.createTemplateFromActiveCompany, payload);
      if (res.error) throw res.error;
      return res;
    },
  },

  reducers: {
    fetchTemplatesSuccess(state, { payload }) {
      const { templates } = payload;
      return {
        ...state,
        templates,
      };
    },

    editPoddingCodeSuccess(state, { payload }) {
      const { code } = payload;
      const list = state.list.map((item) => {
        if (item.id !== code.id) return item;
        return code;
      });
      return {
        ...state,
        list,
      };
    },

    createPoddingCodeSuccess(state, { payload }) {
      const list = [...state.list, { ...payload }];
      return {
        ...state,
        list,
      };
    },

    fetchPoddingCodesSuccess(state, { payload }) {
      const { list, pagination } = payload;
      return {
        ...state,
        list,
        pagination,
      };
    },

    fetchLocationsSuccess(state, { payload }) {
      const { locations } = payload;
      return {
        ...state,
        locationsOfCompany: locations,
      };
    },
  },
};
