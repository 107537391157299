import request from '../utils/request';

export async function fetchReviewRequests(payload) {
  return request('GET', '/admin/publish_review/requests', payload);
}

export async function fetchPastReviewRequests(payload) {
  const data = {
    page: 1,
    per_page: 100,
    reviewable_type: payload.reviewable_type,
    reviewable_id: payload.reviewable_id,
  };
  return request('GET', '/admin/publish_review/requests', data);
}

export async function fetchRequestDetail(payload) {
  const path = '/admin/publish_review/requests/:id'.replace(':id', payload.id);
  return request('GET', path);
}

export async function createReviewHistory(payload) {
  const { id, ...data } = payload;
  const path = '/admin/publish_review/requests/:request_id/histories'.replace(':request_id', id);
  return request('POST', path, data);
}
