import _ from 'lodash';
import { notification } from 'antd';
import { queryCompaniesByName, queryCompanyStaff, addCompanyStaff, editCompanyStaff,
  queryCompanyPositions, editCompanyPosition, deleteCompanyPosition, duplicateCompanyPosition,
  queryCompanyPositionApplications, deleteCompanyPositionApplicant,
  importCompanyPositionApplications, queryCompanyFunctionModules, editCompanyFunctionModules,
  removeCompanyFunctionModules, queryCompanyAllStages, fetchCompanyDetail, updateCompany,
  bindCompanyToCustomer, fetchCompanyBetaFeatures, fetchAllBetas, updateCompanyBetaFeatures,
  fetchAvailableAccountPermission,
} from '../services/company';

const addLevelInfoToItem = (item, level, ancestors) => {
  const { name, key } = item;
  const result = { ...item, level, ...(ancestors && { ancestors }), title: name, value: key };
  if (!item.children) return result;
  if (item.children.length === 0) return result;
  const nextLevel = level + 1;
  // eslint-disable-next-line
  ancestors = [...ancestors, item.key]
  result.children = result.children.map(record => addLevelInfoToItem(record, nextLevel, ancestors));
  return result;
};

export const flattenAvailablePermissionsFn = (acc, item) => {
  if (!item.children) return [...acc, item];
  if (item.children.length === 0) return [...acc, item];
  const newAcc = [item, ...item.children.reduce(flattenAvailablePermissionsFn, [...acc])];
  return newAcc;
};

export default {
  namespace: 'company',

  state: {
    allFunctionModulesList: {},
    functionModulesList: {},
    staffList: {},
    positionsList: {},
    stagesList: [],
    positionApplicationsList: {},
    allLocationsList: [],
    allDepartmentsList: [],
    duplicateLocationsList: [],
    duplicateDepartmentsList: [],
    companiesList: [],
    fetchingCompanies: false,
    detail: {},
    companyBetaFeatures: {
      allBetas: {},
      companyBetas: {},
    },
    availablePermissions: [],
    flattenAvailablePermissions: [],
  },

  effects: {
    *updateCompany({ payload }, { call, put }) {
      const response = yield call(updateCompany, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'company/updateCompanyDetail',
        payload: { detail: response.company },
      });

      if (payload.onComplete) payload.onComplete();
    },
    *fetchCompanyDetail({ payload }, { call, put }) {
      const response = yield call(fetchCompanyDetail, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      yield put({
        type: 'updateCompanyDetail',
        payload: { detail: response.company },
      });
      return response.company;
    },

    *fetchCompanies({ payload }, { call, put }) {
      const { ...params } = payload;

      yield put({
        type: 'updateFetchCompanies',
        payload: {
          fetchCompanies: true,
        },
      });

      const response = yield call(queryCompaniesByName, params);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const companiesList = response.companies;
      yield put({
        type: 'updateCompanyList',
        payload: {
          companiesList,
        },
      });
    },

    *fetchCompanyFunctionModules({ payload }, { call, put }) {
      const response = yield call(queryCompanyFunctionModules, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'setupCompanyFunctionModule',
        payload: {
          allFunctionModulesList: response.all_moduels,
          functionModulesList: response.chosen_modules,
        },
      });
    },
    *editCompanyFunctionModules({ payload }, { call, put }) {
      const response = yield call(editCompanyFunctionModules, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyFunctionModule',
        payload: {
          functionModulesList: response.company.function_module_ids,
        },
      });
    },
    *removeCompanyFunctionModules({ payload }, { call, put }) {
      const response = yield call(removeCompanyFunctionModules, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyFunctionModule',
        payload: {
          functionModulesList: response.company.function_module_ids,
        },
      });
    },
    *fetchCompanyStaffs({ payload }, { call, put }) {
      const response = yield call(queryCompanyStaff, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyStaff',
        payload: {
          list: response.company_staffs,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.data.per_page,
          },
        },
      });
    },
    *fetchCompanyPositions({ payload }, { call, put }) {
      const response = yield call(queryCompanyPositions, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyPositions',
        payload: {
          list: response.positions,
          pagination: {
            total: response.all,
            current: response.current_page,
            pageSize: payload.data.per_page,
          },
        },
      });

      yield put({
        type: 'updateLocationsAndDepartments',
        payload: {
          locations: response.locations,
          departments: response.departments,
        },
      });
    },
    *fetchCompanyPositionApplications({ payload }, { call, put }) {
      const response = yield call(queryCompanyPositionApplications, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyPositionApplications',
        payload: {
          list: response.position_applications,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.data.per_page,
          },
        },
      });
    },
    *fetchCompanyAllStages({ payload }, { call, put }) {
      const response = yield call(queryCompanyAllStages, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateCompanyAllStages',
        payload: response.positions,
      });
    },
    *addCompanyStaff({ payload }, { call, put }) {
      const response = yield call(addCompanyStaff, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'addNewCompanyStaff',
        payload: response.company_staff,
      });

      payload.onComplete();
    },
    *editCompanyStaff({ payload }, { call, put }) {
      const response = yield call(editCompanyStaff, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
      }

      yield put({
        type: 'editExistingCompanyStaff',
        payload: response.company_staff,
      });

      payload.onComplete();
    },
    *editCompanyPosition({ payload }, { call, put }) {
      const response = yield call(editCompanyPosition, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'editExistingCompanyPosition',
        payload: response.position,
      });

      payload.onComplete();
    },
    *duplicateCompanyPosition({ payload }, { call, put }) {
      const response = yield call(duplicateCompanyPosition, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'addNewCompanyPosition',
        payload: response.position,
      });

      if (payload.onComplete) {
        payload.onComplete();
      }
    },
    *removeCompanyPosition({ payload }, { call, put }) {
      const response = yield call(deleteCompanyPosition, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'removeExistingCompanyPosition',
        payload: response.position_id,
      });

      if (payload.onComplete) {
        payload.onComplete();
      }
    },
    *removeCompanyPositionApplicant({ payload }, { call, put }) {
      const response = yield call(deleteCompanyPositionApplicant, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'removeExistingCompanyPositionApplicant',
        payload: response.id,
      });

      if (payload.onComplete) {
        payload.onComplete();
      }
    },
    *importCompanyPositionApplications({ payload }, { call }) {
      const response = yield call(importCompanyPositionApplications, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      if (payload.onComplete) {
        payload.onComplete();
      }
    },

    *bindCompanyToCustomer({ payload }, { call, put }) {
      const response = yield call(bindCompanyToCustomer, payload);
      if (response.error) throw response.error;

      const customer = {
        ...response.customer,
        key: response.customer.id,
      };

      yield put({
        type: 'customerList/updateCurrentCustomer',
        payload: {
          currentCustomer: customer,
        },
      });
    },

    *fetchCompanyBetaFeatures({ payload }, { call, put }) {
      const allBetasResponse = yield call(fetchAllBetas);
      if (allBetasResponse.error) throw allBetasResponse.error;

      const companyBetasResponse = yield call(fetchCompanyBetaFeatures, payload);
      if (companyBetasResponse.error) throw companyBetasResponse.error;
      yield put({
        type: 'fetchCompanyBetasSuccess',
        payload: {
          allBetas: allBetasResponse,
          companyBetaFeatures: companyBetasResponse,
        },
      });
      const { betas: companyBetas } = companyBetasResponse;
      return companyBetas;
    },

    *updateCompanyBetaFeatures({ payload }, { call, put }) {
      const response = yield call(updateCompanyBetaFeatures, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'updateCompanyBetaFeaturesSuccess',
        payload: { response },
      });
    },

    // eslint-disable-next-line
    *fetchAvailableAccountPermission({ payload }, { put, call }) {
      const res = yield call(fetchAvailableAccountPermission);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchAvailableAccountPermissionConfigSuccess',
        payload: {
          availablePermissions: res.permissions,
        },
      });
    },
  },

  reducers: {
    fetchAvailableAccountPermissionConfigSuccess(state, { payload }) {
      const availablePermissions = payload.availablePermissions
        .map((item) => { return addLevelInfoToItem(item, 0, []); });
      const flattenAvailablePermissions = availablePermissions
        .reduce(flattenAvailablePermissionsFn, []);
      return {
        ...state,
        availablePermissions,
        flattenAvailablePermissions,
      };
    },
    updateCompanyDetail(state, { payload }) {
      const { detail } = payload;
      return {
        ...state,
        detail,
      };
    },

    updateCompanyList(state, action) {
      const { companiesList } = action.payload;
      return {
        ...state,
        companiesList,
        fetchingCompanies: false,
      };
    },
    updateCompanySearch(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateFetchCompanies(state, action) {
      const { fetchCompanies } = action.payload;
      return {
        ...state,
        fetchCompanies,
      };
    },
    setupCompanyFunctionModule(state, action) {
      const { payload: { functionModulesList: companyModules } } = action;
      const activeModules = _.isEmpty(companyModules) ? [] : companyModules.map((module) => {
        return module.id;
      });

      return {
        ...state,
        functionModulesList: activeModules,
        allFunctionModulesList: action.payload.allFunctionModulesList,
      };
    },
    updateCompanyFunctionModule(state, action) {
      return {
        ...state,
        functionModulesList: action.payload.functionModulesList,
      };
    },
    updateCompanyStaff(state, action) {
      return {
        ...state,
        staffList: action.payload,
      };
    },
    updateCompanyPositions(state, action) {
      return {
        ...state,
        positionsList: action.payload,
      };
    },
    updateCompanyAllStages(state, action) {
      return {
        ...state,
        stagesList: action.payload,
      };
    },
    updateLocationsAndDepartments(state, action) {
      return {
        ...state,
        allLocationsList: action.payload.locations,
        allDepartmentsList: action.payload.departments,
        duplicateLocationsList: action.payload.locations,
        duplicateDepartmentsList: action.payload.departments,
      };
    },
    updateDuplicateLocationsAndDepartments(state, action) {
      return {
        ...state,
        duplicateLocationsList: action.payload.locations,
        duplicateDepartmentsList: action.payload.departments,
      };
    },
    updateCompanyPositionApplications(state, action) {
      return {
        ...state,
        positionApplicationsList: action.payload,
      };
    },
    addNewCompanyStaff(state, action) {
      const { staffList: { list, pagination } } = state;
      const { total, current, pageSize } = pagination;

      return {
        ...state,
        staffList: {
          list: [
            ...list,
            ...[action.payload],
          ],
          pagination: {
            total: total + 1,
            current,
            pageSize,
          },
        },
      };
    },
    editExistingCompanyStaff(state, action) {
      const { staffList: { list, pagination } } = state;
      const updatedList = list.map((staff) => {
        if (staff.id === action.payload.id) {
          return action.payload;
        }
        return staff;
      });

      return {
        ...state,
        staffList: {
          list: updatedList,
          pagination,
        },
      };
    },
    addNewCompanyPosition(state, action) {
      const { positionsList: { list, pagination } } = state;
      const { total, current, pageSize } = pagination;

      return {
        ...state,
        positionsList: {
          list: [
            ...list,
            ...[action.payload],
          ],
          pagination: {
            total: total + 1,
            current,
            pageSize,
          },
        },
      };
    },
    editExistingCompanyPosition(state, action) {
      const { positionsList: { list, pagination } } = state;
      const updatedList = list.map((position) => {
        if (position.id === action.payload.id) {
          return action.payload;
        }
        return position;
      });

      return {
        ...state,
        positionsList: {
          list: updatedList,
          pagination,
        },
      };
    },
    removeExistingCompanyPosition(state, action) {
      const { positionsList: { list, pagination } } = state;
      const updatedList = list.filter((position) => {
        return position.id !== action.payload;
      });

      return {
        ...state,
        positionsList: {
          list: updatedList,
          pagination,
        },
      };
    },
    removeExistingCompanyPositionApplicant(state, action) {
      const { positionApplicationsList: { list, pagination } } = state;
      const updatedList = list.filter((applicant) => {
        return applicant.id !== action.payload;
      });

      return {
        ...state,
        positionApplicationsList: {
          list: updatedList,
          pagination,
        },
      };
    },

    fetchCompanyBetasSuccess(state, action) {
      const { allBetas, companyBetaFeatures: { betas: companyBetas } } = action.payload;
      return {
        ...state,
        companyBetaFeatures: {
          allBetas,
          companyBetas,
        },
      };
    },

    updateCompanyBetaFeaturesSuccess(state, action) {
      const { betas: companyBetas } = action.payload.response;
      return {
        ...state,
        companyBetaFeatures: {
          ...state.companyBetaFeatures,
          companyBetas,
        },
      };
    },


  },

  subscriptions: {

  },
};
