// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n#root {\n  height: 100%;\n}\n.ant-layout {\n  min-height: 100%;\n}\ncanvas {\n  display: block;\n}\nbody {\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.oCTSK4g0FHq9JFOqfP6r {\n  width: 100%;\n  margin: 40px 0 !important;\n}\n.ant-spin-container {\n  overflow: visible !important;\n}\n", "",{"version":3,"sources":["webpack://./src/index.less"],"names":[],"mappings":"AAAA;;;EAGE,YAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,cAAA;AADF;AAIA;EACE,kCAAA;EACA,mCAAA;EACA,kCAAA;AAFF;AAKA;EACE,WAAA;EACA,yBAAA;AAHF;AAOA;EAEI,4BAAA;AANJ","sourcesContent":["html,\nbody,\n:global(#root) {\n  height: 100%;\n}\n\n:global(.ant-layout) {\n  min-height: 100%;\n}\n\ncanvas {\n  display: block;\n}\n\nbody {\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.globalSpin {\n  width: 100%;\n  margin: 40px 0 !important;\n}\n\n// temp fix for https://github.com/ant-design/ant-design/commit/a1fafb5b727b62cb0be29ce6e9eca8f579d4f8b7\n:global {\n  .ant-spin-container {\n    overflow: visible !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalSpin": "oCTSK4g0FHq9JFOqfP6r"
};
export default ___CSS_LOADER_EXPORT___;
