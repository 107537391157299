// import fetch from 'dva/fetch';
import { notification } from 'antd';
import axiosRetry from 'axios-retry';

// import { routerRedux } from 'dva/router';
import axios from 'axios';
// import store from '../index';
import { APIHOST } from '../config';
import { setAuthority } from '../utils/authority';

// Setup auto retry
axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
});

const codeMessage = {
  // 200: '服务器成功返回请求的数据',
  // 201: '新建或修改数据成功。',
  // 202: '一个请求已经进入后台排队（异步任务）',
  // 204: '删除数据成功。',
  // 400: '发出的请求有错误，服务器没有进行新建或修改数据,的操作。',
  // 401: '用户没有权限（令牌、用户名、密码错误）。',
  // 403: '用户得到授权，但是访问是被禁止的。',
  // 404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  // 406: '请求的格式不可得。',
  // 410: '请求的资源被永久删除，且不会再得到的。',
  // 422: '当创建一个对象时，发生一个验证错误。',
  // 500: '服务器发生错误，请检查服务器',
  // 502: '网关错误',
  // 503: '服务不可用，服务器暂时过载或维护',
  // 504: '网关超时',
};
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `Request error ${response.status}: ${response.url}`,
    description: errortext,
  });
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}

/**
 * Requests a URL
 *
 * @param {string} method The method of the request
 * @param {string} url    The URL we want to request
 * @param {object} data   The data
 */
export default function request(method, url, data) {
  const dataKey = (method === 'POST' || method === 'DELETE' || method === 'PUT') ? 'data' : 'params';
  const requestUrl = url.startsWith('http') ? url : APIHOST + url;
  const config = {
    method,
    url: requestUrl,
    [dataKey]: data,
    withCredentials: true,
  };
  const token = window.localStorage.getItem('WS_TOKEN');
  const userId = window.localStorage.getItem('WS_USER_ID');
  const userEmail = window.localStorage.getItem('WS_USER_EMAIL');
  if (token && token !== 'undefined') {
    config.headers = {
      Authorization: token,
      ...(userId && userId !== 'undefined' && { 'ws-user-id': userId }),
      ...(userEmail && userEmail !== 'undefined' && { 'ws-user-email': userEmail }),
    };
  }
  return axios(config)
    .then(checkStatus)
    .then(response => (response.data))
    .catch((err) => {
      let msg = 'Server error';
      let errorCode = 0;
      let customer;
      let latestRequest;
      if (err.response && err.response.data && err.response.data.error_code) {
        errorCode = err.response.data.error_code;
        if (errorCode === 10003) {
          window.localStorage.setItem('WS_TOKEN', undefined);
          window.localStorage.setItem('WS_USER_ID', undefined);
          window.localStorage.setItem('WS_USER_EMAIL', undefined);
          window.location.href = '/';
          setAuthority('guest');
        }
        msg = Array.isArray(err.response.data.message) ? err.response.data.message.join(', ') : err.response.data.message;
        // eslint-disable-next-line
        customer = err.response.data.customer;
        latestRequest = err.response.data.latest_request;
      }
      return { error: { msg,
        errorCode,
        ...(customer && { customer }),
        ...(latestRequest && { latest_request: latestRequest }),
      } };
    });
}
