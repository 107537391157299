import { notification } from 'antd';
import {
  query, update, updatePayment, addPlan, updateSubscription, cancelSubscription,
  createCharge, refundCharge, toggleRetention, verifyBankAccount, sendVerifyLink,
  payInvoice, createAttachment, destroyAttachment, fetchChargeRefunds,
  fetchSubscriptionHistory, bindCompany, unbindCompany,
  bindMoonclerkCustomer, unbindMoonclerkCustomer, refreshMoonclerkCustomer,
} from '../services/customer';
import { queryAll as queryCompanies } from '../services/company';

export default {
  namespace: 'customer',

  state: {
    customer: null,
    subscriptionHistory: {},
    filteredCompanies: [],
  },

  reducers: {
    updateCustomer(state, { payload }) {
      const { customer } = payload;
      return {
        ...state,
        customer,
      };
    },
    updateChargeRefunds(state, { payload }) {
      const { customer = {} } = state;
      const { refunds } = payload;
      return {
        ...state,
        customer: {
          ...customer,
          refunds,
        },
      };
    },

    updateSubscriptionHistory(state, { payload }) {
      const { subscriptionHistory } = payload;
      return {
        ...state,
        subscriptionHistory,
      };
    },

    filterCompaniesSuccess(state, { payload }) {
      const { list } = payload;
      return {
        ...state,
        filteredCompanies: list,
      };
    },
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const { customer } = response;
      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *update({ payload }, { call, put }) {
      const response = yield call(update, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Update success',
          description: 'Account details successfully updated.',
        });
      }

      const { customer } = response;
      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *addPlan({ payload }, { call }) {
      const response = yield call(addPlan, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Add success',
          description: 'Plan successfully added.',
        });
      }
      // Re-fetch customer
    },

    *refundCharge({ payload }, { call }) {
      const response = yield call(refundCharge, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Refund success',
          description: 'Refund successfully processed.',
        });
      }
      // Re-fetch customer
    },

    *createCharge({ payload }, { call, put }) {
      const response = yield call(createCharge, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        const { charges } = customer;
        if (charges[0] && charges[0].status === 'succeeded') {
          notification.success({
            message: 'Charge succeeded',
            description: 'Customer successfully charged.',
          });
        } else if (charges[0] && charges[0].status === 'failed') {
          notification.error({
            message: 'Charge failed',
            description: charges[0].failure_message,
          });
        } else {
          notification.info({
            message: 'Charge pending',
            description: 'Customer charged. Status is pending.',
          });
        }
      }

      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *toggleRetention({ payload }, { call, put }) {
      const response = yield call(toggleRetention, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Subscription updated',
          description: 'Successfully switched plans.',
        });
      }

      const { customer } = response;
      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *updateSubscription({ payload }, { call, put, select }) {
      const response = yield call(updateSubscription, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Update success',
          description: 'Subscription details successfully updated.',
        });
      }

      const { customer } = yield select(state => state.customer);

      let subscriptions;
      if (customer.subscriptions.length === 0) {
        subscriptions = [response.subscription];
      } else if (
        response.subscription.plan.plan_type === 'position' ||
        response.subscription.plan.plan_type === 'location'
      ) {
        // When editing a 'position' or 'location' plan type subscription we actually cancel the existing one
        // and create a new one, so we need to just use the new one.
        subscriptions = [response.subscription];
      } else {
        subscriptions = customer.subscriptions.map(sub =>
          (sub.id === response.subscription.id ? response.subscription : sub)
        );
      }

      yield put({
        type: 'updateCustomer',
        payload: {
          customer: {
            ...customer,
            subscriptions,
          },
        },
      });
    },

    *cancelSubscription({ payload, callback }, { call, put, select }) {
      const response = yield call(cancelSubscription, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else if (callback) {
        notification.success({
          message: 'Cancel success',
          description: 'Subscription successfully cancelled.',
        });
      }

      const { customer } = yield select(state => state.customer);

      yield put({
        type: 'updateCustomer',
        payload: {
          customer: {
            ...customer,
            subscriptions: [],
          },
        },
      });
    },

    *updatePayment({ payload }, { call, put, select }) {
      const response = yield call(updatePayment, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Update success',
          description: 'Payment details successfully updated.',
        });
      }

      const { source } = response;
      const { customer } = yield select(state => state.customer);

      if (source.brand) {
        yield put({
          type: 'updateCustomer',
          payload: {
            customer: {
              ...customer,
              cards: [source],
            },
          },
        });
      } else if (source.routing_number) {
        yield put({
          type: 'updateCustomer',
          payload: {
            customer: {
              ...customer,
              bank_accounts: [source],
            },
          },
        });
      }
    },

    *sendVerifyLink({ payload }, { call }) {
      const response = yield call(sendVerifyLink, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Send success',
          description: 'Verification link has been sent to customer.',
        });
      }
    },

    *verifyBankAccount({ payload }, { call, put }) {
      const response = yield call(verifyBankAccount, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Verification success',
          description: 'Bank account successfully verified.',
        });
      }

      const { customer } = response;

      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *payInvoice({ payload }, { call }) {
      const response = yield call(payInvoice, payload);

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        const { invoice } = response;
        if (invoice.paid) {
          notification.success({
            message: 'Retry succeeded',
            description: 'Customer successfully charged.\nPlease refresh after few minutes to see the changes.',
          });
        } else {
          notification.error({
            message: 'Retry failed',
            description: 'Please try again later',
          });
        }
      }
    },

    *createAttachment({ payload }, { call, put }) {
      const response = yield call(createAttachment, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *destroyAttachment({ payload }, { call, put }) {
      const response = yield call(destroyAttachment, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      yield put({
        type: 'updateCustomer',
        payload: {
          customer,
        },
      });
    },

    *fetchChargeRefunds({ payload }, { call, put }) {
      const response = yield call(fetchChargeRefunds, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      yield put({
        type: 'updateChargeRefunds',
        payload: {
          refunds: response.refunds,
        },
      });
    },

    *fetchSubscriptionHistory({ payload }, { call, put }) {
      const response = yield call(fetchSubscriptionHistory, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'updateSubscriptionHistory',
        payload: {
          subscriptionHistory: {
            list: response.subscription_events,
            pagination: {
              total: response.all_count,
              current: response.current_page,
              pageSize: payload.per_page,
            },
          },
        },
      });
    },

    *bindCompany({ payload }, { call, put }) {
      const response = yield call(bindCompany, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Bind company success',
          description: 'The company has been bound successfully.',
        });
        yield put({ type: 'updateCustomer', payload: { customer } });
        return response;
      }
    },

    *unbindCompany({ payload }, { call, put }) {
      const response = yield call(unbindCompany, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Unbind company success',
          description: 'The company has been unbound successfully.',
        });
        yield put({ type: 'updateCustomer', payload: { customer } });
        return response;
      }
    },

    *bindMoonclerkCustomer({ payload }, { call, put }) {
      const response = yield call(bindMoonclerkCustomer, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Bind moonclerk plan success',
          description: 'The moonclerk plan has been bound successfully.',
        });
        yield put({ type: 'updateCustomer', payload: { customer } });
        return response;
      }
    },

    *unbindMoonclerkCustomer({ payload }, { call, put }) {
      const response = yield call(unbindMoonclerkCustomer, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Unbind moonclerk plan success',
          description: 'The moonclerk plan has been unbound successfully.',
        });
        yield put({ type: 'updateCustomer', payload: { customer } });
        return response;
      }
    },

    *refreshMoonclerkCustomer({ payload }, { call, put }) {
      const response = yield call(refreshMoonclerkCustomer, payload);
      const { customer } = response;

      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      } else {
        notification.success({
          message: 'Refresh moonclerk plan success',
          description: 'The moonclerk plan has been refreshed successfully.',
        });
        yield put({ type: 'updateCustomer', payload: { customer } });
        return response;
      }
    },

    filterCompanies: [
      function* fetch({ payload }, { put, call }) {
        const response = yield call(queryCompanies, payload);
        if (response.error) {
          notification.error({
            message: `Request error ${response.error.errorCode}`,
            description: response.error.msg,
          });
          return;
        }
        const list = [];
        for (const company of Object.values(response.companies)) {
          list.push({
            id: company.id,
            key: company.id,
            number: company.number,
            name: company.name,
            intro: company.introduction,
            nickname: company.nickname,
            status: company.status,
            tier: company.tier,
          });
        }

        yield put({
          type: 'filterCompaniesSuccess',
          payload: {
            list,
          },
        });

        return true;
      },
      { type: 'takeLatest' },
    ],
  },

  subscriptions: {

  },
};
