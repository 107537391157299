import * as services from '../services/feed';

export default {
  namespace: 'feed',
  state: {
    list: [],
  },

  effects: {
    *fetchFeeds({ paylaod }, { call, put }) {
      const res = yield call(services.fetchFeeds);
      if (res.error) throw res.error;

      yield put({
        type: 'fetchFeedsSuccess',
        payload: {
          list: res.job_boards,
        },
      });
    },
  },

  reducers: {
    fetchFeedsSuccess(state, { payload }) {
      const { list } = payload;

      return {
        ...state,
        list,
      };
    },
  },
};
