import { notification } from 'antd';
import { queryAll, addCompany, updateCompany/* , addCompanyStaff */ } from '../services/company';

export default {
  namespace: 'companyList',

  state: {
    list: [],
    currentCompany: undefined,
  },

  effects: {
    *fetchAll({ payload }, { call, put }) {
      const response = yield call(queryAll, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const list = [];
      for (const company of Object.values(response.companies)) {
        list.push({
          key: company.id,
          id: company.id,
          number: company.number,
          name: company.name,
          intro: company.introduction,
          nickname: company.nickname,
          logo: company.logo.url,
          status: company.status,
          trial: company.trial,
          platform: company.platform,
          trial_days: company.trial_days,
          trial_remaining_days: company.trial_remaining_days,
          franchise_labels: company.franchise_labels,
          customer_id: company.customer_id,
          tier: company.tier,
          brand: company.brand,
        });
      }

      yield put({
        type: 'saveCompanyList',
        payload: {
          list,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },
    *addCompany({ payload }, { call, put }) {
      const response = yield call(addCompany, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const company = [{
        key: response.company.id,
        id: response.company.id,
        number: response.company.number,
        name: response.company.name,
        intro: response.company.introduction,
        nickname: response.company.nickname,
        logo: response.company.logo.thumb.url,
        status: response.company.status,
        tier: response.company.tier,
      }];

      yield put({
        type: 'updateCompanyList',
        payload: company,
      });

      // const payload2 = {
      //   companyId: response.company.id,
      //   email: `support.${response.company.nickname}@workstream.is`,
      //   password: 'password',
      //   title: 'hr',
      //   name: `${response.company.name} Support`,
      // };

      // const response2 = yield call(addCompanyStaff, payload2);
      // if (response2.error) throw response2.error;

      payload.onComplete();
    },
    *editCompany({ payload }, { call, put }) {
      const response = yield call(updateCompany, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const company = {
        key: response.company.id,
        id: response.company.id,
        number: response.company.number,
        name: response.company.name,
        intro: response.company.introduction,
        nickname: response.company.nickname,
        logo: response.company.logo.url,
        status: response.company.status,
        trial: response.company.trial,
        platform: response.company.platform,
        trial_days: response.company.trial_days,
        trial_remaining_days: response.company.trial_remaining_days,
        tier: response.company.tier,
        brand: response.company.brand,
      };

      yield put({
        type: 'editExistingCompany',
        payload: company,
      });

      yield put({
        type: 'company/updateCompanyDetail',
        payload: { detail: response.company },
      });

      if (payload.onComplete) payload.onComplete();
    },
  },

  reducers: {
    saveCompanyList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    updateCompanyList(state, action) {
      const { list: { list, pagination } } = state;
      const { total, current, pageSize } = pagination;

      return {
        ...state,
        list: {
          list: [
            ...list,
            ...action.payload,
          ],
          pagination: {
            total: total + 1,
            current,
            pageSize,
          },
        },
      };
    },

    updateCurrentCompany(state, { payload }) {
      const { currentCompany } = payload;

      return {
        ...state,
        currentCompany,
      };
    },

    editExistingCompany(state, action) {
      const { list: { list = [], pagination } } = state;
      const updatedList = list.map((company) => {
        if (company.key === action.payload.key) {
          return action.payload;
        }
        return company;
      });

      return {
        ...state,
        list: {
          list: updatedList,
          pagination,
        },
      };
    },
  },
};
