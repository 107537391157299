import { ACTION_CABLE_URL } from '../config';

global.receivedMessages = [];
global.receivedAsyncTasks = [];
global.shouldDealReceivedMessages = false;
global.shouldDealReceivedAsyncTasks = false;

const dealWithReceivedAsyncTasks = () => {
  const { webSocket, receivedAsyncTasks = [] } = global;
  const { dispatch } = webSocket;
  const receivedAsyncTasksLength = receivedAsyncTasks.length;
  if (receivedAsyncTasks.length > 0) {
    let newReceivedAsyncTasks = [];
    for (let i = 0; i < receivedAsyncTasksLength; i += 1) {
      newReceivedAsyncTasks = newReceivedAsyncTasks.concat(receivedAsyncTasks.shift());
    }
    const tasks = newReceivedAsyncTasks.map(item => item.async_tasks_batch);
    dispatch({ type: 'asyncTasks/receiveTasks', payload: { tasks } });
  }
};

let cableInterval;
if (cableInterval) clearInterval(cableInterval);
cableInterval = setInterval(() => {
  dealWithReceivedAsyncTasks();
}, 1500);

const ActionCable = require('actioncable');

export function create(dispatch) {
  const webSocket = { dispatch };
  // ActionCable.startDebugging();
  webSocket.cable = ActionCable.createConsumer(ACTION_CABLE_URL);
  // 设置webSocket
  global.webSocket = webSocket;
}

export function subscriptions() {
  const { webSocket } = global;
  const { cable } = webSocket;
  // console.log('-----------subscriptions start-------------------');
  // console.log(cable)
  // console.log('-----------subscriptions end-------------------');
  webSocket.appearance = cable.subscriptions.create({ channel: 'AsyncTaskChannel', admin: true }, {
    connected() {
      console.log('action cable tasks connected start===================');
      console.log('action cable tasks connected end===================');
    },
    disconnected() {
      console.log('action cable tasks disconnected===================');
    },
    received(data) {
      console.log('action cable===tasks received start');
      console.log(JSON.stringify(data));
      console.log('action cable===tasks received end');

      global.receivedAsyncTasks.push(data);
    },
  });
  global.webSocket = webSocket;
}

export function unSubscriptions() {
  const {
    webSocket: { cable, messages, appearance },
  } = global;
  cable.subscriptions.remove(messages);
  cable.subscriptions.remove(appearance);
}
