import request from '../utils/request';

export async function fetchExternalConnectors() {
  return request('GET', '/admin/onboarding_microservices_metadatas');
}

export async function fetchConnectorMetaData(payload) {
  const { id } = payload;
  return request('GET', `/admin/onboarding_microservices_metadatas/${id}`);
}

export async function saveConnector(payload) {
  return request('POST', '/admin/onboarding_microservices_metadatas/import_from_url', payload);
}

export async function deleteConnector(payload) {
  const { id } = payload;
  return request('DELETE', `/admin/onboarding_microservices_metadatas/${id}`);
}

export async function updateConnectorAccess(payload) {
  const { id, accessControl } = payload;
  const params = {
    onboarding_microservices_metadata: {
      access_control: accessControl,
    },
  };
  return request('PUT', `/admin/onboarding_microservices_metadatas/${id}`, params);
}
