import request from '../utils/request';

export async function create(payload) {
  const {
    user_id: userId,
    async_tasks_batch: asyncTasksBatch,
  } = payload;
  const params = { async_tasks_batch: asyncTasksBatch };
  const path = '/admin/admin_users/:user_id/async_tasks/batches'.replace(':user_id', userId);
  return request('POST', path, params);
}

export async function query(payload) {
  const { user_id: userId, status } = payload;
  const params = status ? { status } : {};
  const path = '/admin/admin_users/:user_id/async_tasks/batches'.replace(':user_id', userId);
  return request('GET', path, params);
}

export async function get(payload) {
  const { user_id: userId, id } = payload;
  const path = '/admin/async_tasks/batches/:id'.replace(':user_id', userId).replace(':id', id);
  return request('GET', path);
}

export async function querySubTasks(payload) {
  const { id } = payload;
  const path = '/admin/async_tasks/batches/:id/tasks'.replace(':id', id);
  return request('GET', path);
}
