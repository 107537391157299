import request from '../utils/request';
import { APIHOST } from '../config';
import { toFormData } from '../utils/utils';

export async function fetchCompanyDetail(params) {
  const { id } = params;
  return request('GET', `/admin/companies/${id}`);
}

export async function queryAll(params) {
  return request('GET', '/admin/companies', params);
}

export async function queryCompaniesByName(params) {
  return request('GET', '/admin/companies/search', params);
}

export async function addCompany(params) {
  const data = toFormData(params);
  return request(
    'POST',
    '/admin/companies',
    data,
  );
}

export async function updateCompany(params) {
  const { id, company } = params;
  const data = toFormData({ company });
  return request(
    'PUT',
    `/admin/companies/${id}`,
    data,
  );
}

export async function queryCompanyStaff(params) {
  const { id, data } = params;
  return request('GET', `/admin/companies/${id}/company_staffs`, data);
}

export async function addCompanyStaff(params) {
  const {
    companyId, email, password, title, name,
    is_support_staff: isSupportStaff, region_number: regionNumber,
    phone, global_phone_number: globalPhoneNumber, avatar, role, admin,
  } = params;
  const data = new window.FormData();
  if (email) data.append('user[email]', email);
  if (password) data.append('user[password]', password);
  if (title) data.append('company_staff[title]', title);
  if (name) data.append('company_staff[name]', name);
  if (regionNumber) data.append('company_staff[region_number]', regionNumber);
  if (phone) data.append('company_staff[phone]', phone);
  if (globalPhoneNumber) data.append('company_staff[global_phone_number]', globalPhoneNumber);
  if (avatar) data.append('company_staff[avatar]', avatar);
  if (isSupportStaff) data.append('company_staff[is_support_staff', isSupportStaff === 'true');
  if (role) data.append('company_admin[role]', role);
  if (admin) {
    const values = Object.keys(admin).map((key) => {
      return admin[key];
    });
    for (const right of values) {
      data.append(`company_admin[${right}]`, 1);
    }
  }

  return request(
    'POST',
    `/admin/companies/${companyId}/company_staffs`,
    data,
  );
}

export async function editCompanyStaff(params) {
  const {
    companyId, email, password, title, name,
    is_support_staff: isSupportStaff, region_number: regionNumber,
    phone, global_phone_number: globalPhoneNumber, avatar, staffId, role, admin,
  } = params;
  const data = new window.FormData();
  if (email) data.append('user[email]', email);
  if (password) data.append('user[password]', password);
  if (title) data.append('company_staff[title]', title);
  if (name) data.append('company_staff[name]', name);
  if (regionNumber) data.append('company_staff[region_number]', regionNumber);
  data.append('company_staff[phone]', phone);
  if (globalPhoneNumber) data.append('company_staff[global_phone_number]', globalPhoneNumber);
  if (avatar) data.append('company_staff[avatar]', avatar);
  if (isSupportStaff) data.append('company_staff[is_support_staff', isSupportStaff === 'true');
  if (role) data.append('company_admin[role]', role);
  if (admin) {
    const values = Object.keys(admin).map((key) => {
      return admin[key];
    });
    for (const right of values) {
      data.append(`company_admin[${right}]`, 1);
    }
  }

  return request(
    'PUT',
    `/admin/companies/${companyId}/company_staffs/${staffId}`,
    data,
  );
}

export async function queryCompanyFunctionModules(params) {
  const { id } = params;
  return request('GET', `/admin/companies/${id}/function_modules`);
}

export async function editCompanyFunctionModules(params) {
  const { id, data } = params;
  return request('POST', `/admin/companies/${id}/choose_function_modules`, data);
}

export async function removeCompanyFunctionModules(params) {
  const { id, data } = params;
  return request('POST', `/admin/companies/${id}/remove_function_modules`, data);
}

export async function queryCompanyPositions(params) {
  const { id, data } = params;
  return request('GET', `/admin/companies/${id}/positions`, data);
}

export async function editCompanyPosition(params) {
  const { companyId, positionId, position } = params;
  return request('PUT', `/admin/companies/${companyId}/positions/${positionId}`, { position });
}

export async function queryCompanyAllStages(params) {
  const { id } = params;
  return request('GET', `/admin/companies/${id}/all_stages`);
}

export async function duplicateCompanyPosition(params) {
  const { companyId, positionId, position } = params;
  return request('POST', `/admin/companies/${companyId}/positions/${positionId}/duplicate`, { position });
}

export async function deleteCompanyPosition(params) {
  const { companyId, positionId } = params;
  return request('DELETE', `/admin/companies/${companyId}/positions/${positionId}`);
}

export async function queryCompanyPositionApplications(params) {
  const { id, data } = params;
  return request('GET', `/admin/companies/${id}/position_applications`, data);
}

export async function importCompanyPositionApplications(params) {
  const { data } = params;
  return request('POST', '/admin/position_applications/import', data);
}

export async function deleteCompanyPositionApplicant(params) {
  const { applicantId } = params;
  return request('DELETE', `/admin/position_applications/${applicantId}`);
}

export async function bindCompanyToCustomer(params) {
  const { companyId, customerId } = params;
  const path = '/admin/companies/:id/bind_customer'.replace(':id', companyId);
  return request('POST', path, { customer_id: customerId });
}

export async function fetchCompanyBetaFeatures(params) {
  const { id } = params;
  const api = APIHOST.replace('/v1', '');
  return request('GET', `${api}/beta_feature/Company/${id}/setting`);
}

export async function fetchAllBetas() {
  const api = APIHOST.replace('/v1', '');
  return request('GET', `${api}/beta_feature/all`);
}

export async function updateCompanyBetaFeatures(payload) {
  const { id, betas } = payload;
  const api = APIHOST.replace('/v1', '');
  return request('PUT', `${api}/beta_feature/Company/${id}/setting`, betas);
}

export async function fetchAvailableAccountPermission() {
  const path = '/settings/permissions';
  return request('GET', APIHOST + path);
}

