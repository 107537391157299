import * as services from '../services/labels';

export default {
  namespace: 'labels',
  state: {
    list: [],
  },

  effects: {
    *fetchLabels({ payload }, { call, put }) {
      const res = yield call(services.fetchLabels, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchLabelsSuccess',
        payload: {
          list: res.franchise_labels,
        },
      });
    },

    *createLabel({ payload }, { call, put }) {
      const res = yield call(services.createLabel, payload);
      if (res.error) throw res.error;
    },

    *deleteLabel({ payload }, { call, put }) {
      const res = yield call(services.deleteLabel, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'deleteLabelSuccess',
        payload: { id: payload.id },
      });
    },
  },

  reducers: {
    deleteLabelSuccess(state, { payload }) {
      const list = state.list.filter(item => item.id !== payload.id);
      return {
        ...state,
        list,
      };
    },
    editLabelSuccess(state, { payload }) {
      const { franchise_label: label } = payload;
      const list = state.list.map((item) => {
        if (item.id !== label.id) return item;
        return label;
      });
      return {
        ...state,
        list,
      };
    },

    fetchLabelsSuccess(state, { payload }) {
      const { list } = payload;
      return {
        ...state,
        list,
      };
    },
  },
};
