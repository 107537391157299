/* eslint-disable require-yield */
import * as WebSocket from '../services/webSocket';

export default {
  namespace: 'webSocket',

  state: {
    cable: null,
    conversations: null,
  },

  reducers: {},

  effects: {
    *subscriptions() {
      WebSocket.subscriptions();
    },

    *unSubscriptions() {
      WebSocket.unSubscriptions();
    },
  },

  subscriptions: {},
};
