import { notification } from 'antd';
import { query, create } from '../services/baseBrands';

export default {
  namespace: 'baseBrands',

  state: {
    filteredBaseBrands: [],
  },

  reducers: {
    filterBaseBrandsSuccess(state, { payload }) {
      const { baseBrands } = payload;
      return { ...state, filteredBaseBrands: baseBrands };
    },

    createBaseBrandSuccess(state, { payload }) {
      const { filteredBaseBrands } = state;
      const { baseBrand } = payload;
      return { ...state, filteredBaseBrands: [...filteredBaseBrands, baseBrand] };
    },
  },

  effects: {
    *create({ payload }, { call, put }) {
      const response = yield call(create, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      const { base_brand: baseBrand } = response;

      yield put({ type: 'createBaseBrandSuccess', payload: { baseBrand } });
    },

    filterBaseBrands: [
      function* fetch({ payload }, { put, call }) {
        const response = yield call(query, payload);
        if (response.error) {
          notification.error({
            message: `Request error ${response.error.errorCode}`,
            description: response.error.msg,
          });
          return;
        }
        const { base_brands: baseBrands } = response;
        yield put({
          type: 'filterBaseBrandsSuccess',
          payload: { baseBrands },
        });

        return true;
      },
      { type: 'takeLatest' },
    ],
  },

  subscriptions: {

  },
};
