import { notification } from 'antd';
import { query } from '../services/invoice';

export default {
  namespace: 'invoice',

  state: {
    invoice: null,
  },

  reducers: {
    updateInvoice(state, { payload }) {
      const { invoice } = payload;
      return {
        ...state,
        invoice,
      };
    },
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.error) {
        const { errorCode, msg } = response.error;
        notification.error({
          message: `Request error ${errorCode}`,
          description: msg,
        });
        return;
      }

      yield put({
        type: 'updateInvoice',
        payload: {
          invoice: response.invoice,
        },
      });
    },
  },

  subscriptions: {

  },
};
