import { isUrl } from '../utils/utils';

const menuData = [{
  name: 'Home',
  icon: 'dashboard',
  path: 'dashboard',
  children: [{
    name: 'Companies',
    path: 'companies',
  }, {
    name: 'Customers',
    path: 'customers',
  }, {
    name: 'Announcements',
    path: 'announcements',
  }, {
    name: 'Users',
    path: 'adminUsers',
  }, {
    name: 'Reports',
    path: 'reports/1',
    // children: [{
    //   name: 'Mode Reports',
    //   path: 'mode',
    // }],
  }, {
    name: 'Account Templates',
    path: 'accountTemplates',
  }, {
    name: 'Corporations',
    path: 'corporations',
  }, {
    name: 'Labels',
    path: 'labels',
  },
  {
    name: 'External Connectors',
    path: 'external_connectors',
  },
  ],
}, {
  name: 'Job Board',
  icon: 'profile',
  path: 'jobboard',
  children: [{
    name: 'Posts Review',
    path: 'job_postings',
  }, {
    name: 'Feeds',
    path: 'feeds',
  }],
}];

function formatter(data, parentPath = '', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);
