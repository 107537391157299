import { notification } from 'antd';
import { queryAll, addCorporation, updateCorporation, addCompaniesToCorporation } from '../services/corporation';
import { queryAll as queryAllCompanies } from '../services/company';

export default {
  namespace: 'corporationList',

  state: {
    list: [],
    availableCompanies: [],
    totalAvailableCompaniesCount: 0,
  },

  effects: {
    *fetchAll({ payload }, { call, put }) {
      const response = yield call(queryAll, payload);
      if (response.error) throw response.error;
      const list = [];
      for (const corporation of Object.values(response.corporations)) {
        list.push({
          key: corporation.id,
          name: corporation.name,
          intro: corporation.introduction,
          nickname: corporation.nickname,
          logo: corporation.logo.url,
          status: corporation.status,
          bannerImages: corporation.banner_images,
          questions: corporation.career_page_questions,
          number: corporation.number,
        });
      }

      yield put({
        type: 'saveCorporationList',
        payload: {
          list,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },
    *addCorporation({ payload }, { call, put }) {
      const response = yield call(addCorporation, payload);
      if (response.error) throw response.error;

      const corporation = [{
        key: response.corporation.id,
        name: response.corporation.name,
        intro: response.corporation.introduction,
        nickname: response.corporation.nickname,
        logo: response.corporation.logo.url,
        status: response.corporation.status,
        bannerImages: response.corporation.banner_images,
        questions: response.corporation.career_page_questions,
        number: response.corporation.number,
      }];

      yield put({
        type: 'updateCorporationList',
        payload: corporation,
      });

      payload.onComplete();
    },
    *editCorporation({ payload }, { call, put }) {
      const response = yield call(updateCorporation, payload);
      if (response.error) throw response.error;

      const corporation = {
        key: response.corporation.id,
        name: response.corporation.name,
        intro: response.corporation.introduction,
        nickname: response.corporation.nickname,
        logo: response.corporation.logo.url,
        status: response.corporation.status,
        bannerImages: response.corporation.banner_images,
        questions: response.corporation.career_page_questions,
        number: response.corporation.number,
      };

      yield put({
        type: 'editExistingCorporation',
        payload: corporation,
      });

      if (payload.onComplete) payload.onComplete();
    },

    *fetchAvailableCompanies({ payload }, { call, put }) {
      const res = yield call(queryAllCompanies, payload);
      if (res.error) throw res.error;
      yield put({
        type: 'fetchAvailableCompaniesSuccess',
        payload: {
          availableCompanies: res.companies,
          totalAvailableCompaniesCount: res.all_count,
        },
      });
    },

    *addCompaniesToCorporation({ payload }, { call, put }) {
      const res = yield call(addCompaniesToCorporation, payload);
      if (res.error) throw res.error;
    },
  },

  reducers: {
    fetchAvailableCompaniesSuccess(state, { payload }) {
      const { availableCompanies, totalAvailableCompaniesCount } = payload;
      return {
        ...state,
        totalAvailableCompaniesCount,
        availableCompanies,
      };
    },

    saveCorporationList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    updateCorporationList(state, action) {
      const { list: { list, pagination } } = state;
      const { total, current, pageSize } = pagination;

      return {
        ...state,
        list: {
          list: [
            ...list,
            ...action.payload,
          ],
          pagination: {
            total: total + 1,
            current,
            pageSize,
          },
        },
      };
    },
    editExistingCorporation(state, action) {
      const { list: { list, pagination } } = state;
      const updatedList = list.map((corporation) => {
        if (corporation.key === action.payload.key) {
          return action.payload;
        }
        return corporation;
      });

      return {
        ...state,
        list: {
          list: updatedList,
          pagination,
        },
      };
    },
  },
};
