import { query, queryAll, create, update, destroy, reorder } from '../services/reports';

export default {
  namespace: 'modeReports',

  state: {
    reports: {},
    currentReport: null,
  },

  reducers: {
    saveCurrentReport(state, { payload }) {
      const { report: currentReport } = payload;

      return {
        ...state,
        currentReport,
      };
    },

    saveReports(state, { payload }) {
      const { reports } = payload;

      return {
        ...state,
        reports,
      };
    },
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.error) {
        throw response.error;
      }

      const { admin_report: report } = response;

      yield put({
        type: 'saveCurrentReport',
        payload: {
          report,
        },
      });
    },

    *fetchAll({ payload }, { call, put }) {
      const response = yield call(queryAll, payload);
      if (response.error) {
        throw response.error;
      }

      const list = response.admin_reports;

      yield put({
        type: 'saveReports',
        payload: {
          reports: {
            list,
            pagination: {
              total: response.all_count,
              current: response.current_page,
              pageSize: payload.per_page,
            },
          },
        },
      });
    },

    *addReport({ payload }, { call, put, select }) {
      const response = yield call(create, payload);
      if (response.error) {
        throw response.error;
      }

      const report = response.admin_report;

      const { reports } = yield select(state => state.modeReports);
      const updatedReports = {
        reports: {
          list: [
            ...reports.list,
            report,
          ],
          pagination: {
            ...reports.pagination,
            total: reports.pagination.total + 1,
          },
        },
      };

      yield put({
        type: 'saveReports',
        payload: updatedReports,
      });
    },

    *editReport({ payload }, { call, put, select }) {
      const response = yield call(update, payload);
      if (response.error) {
        throw response.error;
      }

      const updatedReport = response.admin_report;
      const { reports } = yield select(state => state.modeReports);
      const updatedList = reports.list.map((report) => {
        if (report.id === updatedReport.id) {
          return updatedReport;
        }
        return report;
      });
      const updatedReports = {
        reports: {
          list: updatedList,
          pagination: reports.pagination,
        },
      };

      yield put({
        type: 'saveReports',
        payload: updatedReports,
      });
    },

    *deleteReport({ payload }, { call, put, select }) {
      const response = yield call(destroy, payload);
      if (response.error) {
        throw response.error;
      }

      const deletedReportId = response.admin_report_id;

      const { reports } = yield select(state => state.modeReports);

      const updatedList = reports.list.filter(report => report.id !== deletedReportId);
      const updatedReports = {
        reports: {
          list: updatedList,
          pagination: {
            ...reports.pagination,
            total: reports.pagination.total - 1,
          },
        },
      };

      yield put({
        type: 'saveReports',
        payload: updatedReports,
      });
    },

    *reorderReports({ payload }, { call, put }) {
      const response = yield call(reorder, payload);
      if (response.error) {
        throw response.error;
      }

      const list = response.admin_reports;

      yield put({
        type: 'saveReports',
        payload: {
          reports: {
            list,
            pagination: {
              total: response.all_count,
              current: response.current_page,
              pageSize: payload.per_page,
            },
          },
        },
      });
    },

  },

  subscriptions: {

  },
};
