import request from '../utils/request';

export async function queryCurrent() {
  return request('GET', '/admin/admin_users/me');
}

export async function queryAll(params) {
  return request('GET', '/admin/admin_users', params);
}

export async function query(params) {
  const { id } = params;
  return request('GET', `/admin/admin_users/${id}`);
}

export async function addAdminUser(params) {
  const data = new FormData();
  const { onComplete, ...columns } = params;

  for (const i of Object.keys(columns)) {
    data.set(`admin_user[${i}]`, columns[i]);
  }
  return request(
    'POST',
    '/admin/admin_users/',
    data,
  );
}

export async function updateAdminUser(params) {
  const data = new FormData();
  const { onComplete, id, ...columns } = params;

  for (const i of Object.keys(columns)) {
    data.set(`admin_user[${i}]`, columns[i]);
  }
  return request(
    'PUT',
    `/admin/admin_users/${id}`,
    data,
  );
}
