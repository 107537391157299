import { getS3UploadUrl } from '../services/attachments';

export default {
  namespace: 'attachments',

  state: {
    s3UploadUrl: '',
  },

  effects: {
    *getS3UploadUrl({ payload }, { call, put }) {
      const response = yield call(getS3UploadUrl, payload);
      if (response.error) {
        return;
      }

      const { presigned_url: s3UploadUrl } = response;
      yield put({
        type: 'getS3UploadUrlSuccess',
        payload: { s3UploadUrl },
      });

      return response;
    },
  },

  reducers: {
    getS3UploadUrlSuccess(state, { payload }) {
      const { s3UploadUrl } = payload;
      return {
        ...state,
        s3UploadUrl,
      };
    },
  },

  subscriptions: {

  },
};
