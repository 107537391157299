import { notification } from 'antd';
import {
  queryAllProducts, queryAll, query, addCustomer, update,
  addPlan, updateSubscription, requestPayment,
} from '../services/customer';

export default {
  namespace: 'customerList',

  state: {
    products: [],
    currentCustomer: undefined,
    list: [],
    pagination: {},
  },

  effects: {
    *fetchAllProducts({ payload }, { call, put }) {
      const response = yield call(queryAllProducts, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const { products } = response;
      yield put({
        type: 'saveProducts',
        payload: {
          products,
        },
      });
    },

    *fetchAll({ payload }, { call, put }) {
      const response = yield call(queryAll, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const list = [];
      for (const customer of Object.values(response.customers)) {
        list.push({
          ...customer,
          key: customer.id,
        });
      }

      yield put({
        type: 'saveCustomerList',
        payload: {
          list,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },

    *fetchCustomer({ payload }, { call, put, select }) {
      const response = yield call(query, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const customer = {
        ...response.customer,
        key: response.customer.id,
      };

      yield put({
        type: 'updateCurrentCustomer',
        payload: {
          currentCustomer: customer,
        },
      });

      let { list } = yield select(state => state.customerList);
      const { pagination } = yield select(state => state.customerList);
      list = list.map(cust => (cust.key === customer.key ? customer : cust));

      yield put({
        type: 'saveCustomerList',
        payload: {
          list,
          pagination,
        },
      });

      return response;
    },

    *addCustomer({ payload }, { call, put }) {
      const response = yield call(addCustomer, payload);
      if (response.error) {
        if (response.error.errorCode !== 10026) {
          notification.error({
            message: `Request error ${response.error.errorCode}`,
            description: response.error.msg,
          });
        }
        return response.error;
      }

      const customer = {
        ...response.customer,
        key: response.customer.id,
      };

      yield put({
        type: 'updateCustomerList',
        payload: customer,
      });

      yield put({
        type: 'updateCurrentCustomer',
        payload: {
          currentCustomer: customer,
        },
      });
    },

    *editCustomer({ payload }, { call, put }) {
      const response = yield call(update, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      const customer = {
        ...response.customer,
        key: response.customer.id,
      };

      yield put({
        type: 'editExistingCustomer',
        payload: customer,
      });
    },

    *addPlan({ payload }, { call }) {
      const response = yield call(addPlan, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      // TODO: Update customer with plan(s)?
    },

    *addSubscription({ payload }, { call }) {
      const response = yield call(updateSubscription, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }

      // TODO: Update customer with subscriptions(s)?
    },

    *requestPayment({ payload }, { call }) {
      const response = yield call(requestPayment, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return response.error;
      }
    },
  },

  reducers: {
    saveProducts(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    saveCustomerList(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    updateCustomerList(state, { payload }) {
      const { list } = state;

      return {
        ...state,
        list: [
          ...list,
          payload,
        ],
      };
    },

    updateCurrentCustomer(state, { payload }) {
      const { currentCustomer } = payload;

      return {
        ...state,
        currentCustomer,
      };
    },

    editExistingCustomer(state, { payload }) {
      const { list } = state;
      const updatedList = list.map((customer) => {
        if (customer.key === payload.key) {
          return payload;
        }
        return customer;
      });

      return {
        ...state,
        list: updatedList,
      };
    },
  },
};
