const apiVersion = '/v1';
let api = '';
let jobHost = '';
let actionCableUrl = '';
let stripeKey = '';
let release = 'default release';
// This DSN is for production environment only
const sentryDSN = 'https://2c7e63fa68ae45fc9b38a71e0234463f@o404030.ingest.sentry.io/5655825';

if (process.env.DEPLOY_ENV === 'production') {
  api = `https://api.workstream.is${apiVersion}`;
  jobHost = 'https://jobs.workstream.us';
  actionCableUrl = 'wss://api.workstream.is/cable';
  stripeKey = 'pk_live_14WiqyVAI66c4TfAMLqmSOay';
} else if (process.env.DEPLOY_ENV === 'develop') {
  api = `https://api-dev.workstream.is${apiVersion}`;
  jobHost = 'https://jobs-dev.workstream.is';
  actionCableUrl = 'wss://api-dev.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'development') {
  api = `https://api-dev.workstream.is${apiVersion}`;
  jobHost = 'https://jobs-dev.workstream.us';
  actionCableUrl = 'wss://api-dev.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'staging') {
  api = `https://api-staging.workstream.is${apiVersion}`;
  // api = `${process.env.PLAYGROUND_API}${apiVersion}`;
  jobHost = 'https://jobs-staging.workstream.us';
  actionCableUrl = 'wss://api-staging.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'staging-1') {
  api = `https://api-staging-1.workstream.is${apiVersion}`;
  jobHost = 'https://jobs-staging-1.workstream.us';
  actionCableUrl = 'wss://api-staging-1.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'staging-2') {
  api = `https://api-staging-2.workstream.is${apiVersion}`;
  jobHost = 'https://jobs-staging-2.workstream.us';
  actionCableUrl = 'wss://api-staging-2.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'staging-3') {
  api = `https://api-staging-3.workstream.is${apiVersion}`;
  jobHost = 'https://jobs-staging-3.workstream.us';
  actionCableUrl = 'wss://api-staging-3.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'sandbox') {
  api = `https://api-sandbox.workstream.us${apiVersion}`;
  jobHost = 'https://jobs-sandbox.workstream.us';
  actionCableUrl = 'wss://api-sandbox.workstream.us/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'local') {
  if (process.env.WS_API_BASE) {
    api = `${process.env.WS_API_BASE}${apiVersion}`;
  } else {
    api = `http://localhost:3000${apiVersion}`;
  }
  jobHost = 'http://localhost:4000';
  actionCableUrl = process.env.WS_CABLE_BASE || 'ws://localhost:3000/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else if (process.env.DEPLOY_ENV === 'playground') {
  api = `${process.env.PLAYGROUND_API}${apiVersion}`;
  jobHost = 'https://jobs-staging.workstream.us';
  actionCableUrl = 'wss://api-staging.workstream.is/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
} else { // 'development'
  if (process.env.WS_API_BASE) {
    api = `${process.env.WS_API_BASE}${apiVersion}`;
  } else {
    api = `http://localhost:3000${apiVersion}`;
  }
  jobHost = 'http://localhost:4000';
  actionCableUrl = process.env.WS_CABLE_BASE || 'ws://localhost:3000/cable';
  stripeKey = 'pk_test_cNq8PRWql0SRusRVsGAnUCaf';
}

if (process.env.SENTRY_RELEASE) {
  release = process.env.SENTRY_RELEASE;
} else {
  release = 'default release';
}
export const APIHOST = api;
export const JOB_HOST = jobHost;
export const STRIPE_KEY = stripeKey;
export const ACTION_CABLE_URL = actionCableUrl;
export const SENTRY_RELEASE_VERSION = release;
export const SENTRY_DSN = sentryDSN;
