// import { notification } from 'antd';
import * as services from '../services/externalConnectors';

export default {
  namespace: 'externalConnectors',
  state: {
    list: [],
  },

  effects: {
    *fetchConnectorMetadata({ payload }, { call }) {
      const response = yield call(services.fetchConnectorMetaData, payload);
      if (response.error) throw response.error;
      return response;
    },

    *fetchExternalConnectors({ payload }, { call, put }) {
      const response = yield call(services.fetchExternalConnectors);
      if (response.error) throw response.error;
      yield put({
        type: 'fetchConnectorsSuccess',
        payload: response,
      });
    },

    *saveConnector({ payload }, { call, put }) {
      const response = yield call(services.saveConnector, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'addConnector',
        payload: response,
      });
    },

    *deleteConnector({ payload }, { call, put }) {
      const response = yield call(services.deleteConnector, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'deleteConnectorSuccess',
        payload,
      });
    },

    *updateConnectorAccess({ payload }, { call, put }) {
      const response = yield call(services.updateConnectorAccess, payload);
      if (response.error) throw response.error;
      yield put({
        type: 'updateConnectorAccessSuccess',
        payload: response,
      });
    },
  },

  reducers: {
    addConnector(state, { payload }) {
      return {
        ...state,
        list: [...state.list, { ...payload }],
      };
    },

    fetchConnectorsSuccess(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },

    deleteConnectorSuccess(state, { payload }) {
      const { id } = payload;
      const newList = state.list.filter((item) => {
        return item.id !== id;
      });
      return {
        ...state,
        list: newList,
      };
    },

    updateConnectorAccessSuccess(state, { payload }) {
      const { id } = payload;
      const newList = state.list.map((item) => {
        if (item.id !== id) return item;
        const newItem = {
          ...item,
          access_control: payload.access_control,
        };
        return newItem;
      });
      return {
        ...state,
        list: newList,
      };
    },
  },
};
