export default {
  addCustomerStages: {
    basic: 'basic',
    bind_customer: 'bind_customer',
    basic_location: 'basic_location',
    plan: 'plan',
    payment: 'payment',
  },

  CreateCustomerStages: {
    company: 'customer-company',
    brand: 'customer-brand',
    locations: 'customer-locations',
    customer: 'customer-customer',
    plan: 'customer-plan',
    payment: 'customer-payment',
    bind_customer: 'customer-bind-customer',
  },

  EditCustomerStages: {
    customer: 'customer-customer',
  },

  CreateCompanyStages: {
    company: 'company-company',
    brand: 'company-brand',
    locations: 'company-locations',
  },

  EditCompanyStages: {
    company: 'company-company',
    brand: 'company-brand',
  },

  CreateCustomerForCompanyStages: {
    customer: 'bind-customer-customer',
    plan: 'bind-customer-plan',
    payment: 'bind-customer-payment',
    bind_customer: 'bind-customer-bind-customer',
  },

  STRINGS: {
    SEARCH_TYPE_LOCATION: 'location',
    SEARCH_TYPE_DEPARTMENT: 'department',
    ADDRESS_FILL_TYPE_GOOGLE: 'google',
    ADDRESS_FILL_TYPE_MANUALLY: 'manually',
  },

  customerMenus: {
    accountDetails: 'Account details',
    paymentDetails: 'Payment details',
    subscriptionDetails: 'Subscription details',
    paymentHistory: 'Payment history',
  },
  refundReasons: {
    trial_not_convert: 'Trial/POC did not convert',
    charged_too_much: 'Was charged too much',
    negotiated_new_price: 'Negotiated a new price',
    cancelled_but_still_charged: 'Cancelled but still charged',
    terminated_customer: 'Customer was terminated by WS',
    stolen_credit_card: 'Stolen credit card',
    terminated_contract: 'Terminated contract early',
    change_credit_card: 'Change credit card',
    contract_not_received_prior_to_charge: 'Contract not received prior to charge',
    trial_extended: 'Trial/POC was extended',
    others: 'Others',
  },
  ASYNC_TASK_TYPE: {
    MOVE_APPLICANTS_TO_NEXT_STAGE: 'move_applicant_to_next_stage',
    BULK_UPDATE_POSITIONS: 'update_position',
    BUlK_IMPORT_APPLICANTS: 'import_position_application',
  },

  tierFunctionModules: {
    basic: ['career_page', 'job_posting', 'add_new_position', 'twilio_short_code', 'job_target'],
    hiring: ['career_page', 'job_posting', 'data_analytics', 'indeed_job_posting', 'scheduling', 'background_checking', 'unlist_position', 'pdf_in_email', 'add_new_position', 'application_rating', 'twilio_short_code', 'job_target', 'manual_billing_sponsored_boards', 'location_filter_group', 'wotc_pre_questionnaire', 'messaging', 'hiring_stages'],
    onboarding: ['career_page', 'online_signing', 'job_posting', 'data_analytics', 'indeed_job_posting', 'scheduling', 'background_checking', 'unlist_position', 'pdf_in_email', 'add_new_position', 'application_rating', 'twilio_short_code', 'onboarding', 'job_target', 'manual_billing_sponsored_boards', 'location_filter_group', 'wotc_pre_questionnaire', 'messaging', 'hiring_stages'],
  },

  postingReviewStatus: {
    pending: 'pending',
    flagged: 'flagged',
    approved: 'approved',
    rejected: 'rejected',
    parent_not_approved: 'parent_not_approved',
  },

  postingReviewStatusDisplay: {
    pending: 'to be reviewed',
    flagged: 'require changes',
    approved: 'approved',
    rejected: 'mark as scam',
    parent_not_approved: 'Company/location not approved',
  },

  errorCode: {
    reviewRequestUpdated: 40022,
    reviewRequestReplaced: 40021,
  },

  planType: {
    fixed: 'Fixed Price',
    location: 'Per Location',
    position: 'Per Position',
    location_matrix: 'Location Matrix',
    retention: 'Retention',
    fixed_locations: 'Fixed Units',
  },

};
