import request from '../utils/request';

export async function query(params) {
  return request('GET', '/admin/announcements', params);
}

export async function create(params) {
  return request('POST', '/admin/announcements', params);
}

export async function update(params) {
  const { id } = params;
  return request('PUT', `/admin/announcements/${id}`, params);
}

export async function destroy(params) {
  const { id } = params;
  return request('DELETE', `/admin/announcements/${id}`);
}
