import '@babel/polyfill';
import 'url-polyfill';
import dva from 'dva';

import { createHashHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import createLoading from 'dva-loading';
// import 'moment/locale/zh-cn';
import FastClick from 'fastclick';
import { notification } from 'antd';
import * as WebSocket from './services/webSocket';
import { SENTRY_RELEASE_VERSION, SENTRY_DSN } from './config';
import router from './router';
import globalModel from './models/global';

import './index.less';


// init Sentry for error tracking
if (process.env.DEPLOY_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler for finer control of sentry
    tracesSampleRate: 0.005,
    release: SENTRY_RELEASE_VERSION,
  });
}
// 1. Initialize
const app = dva({
  history: createHashHistory(),
  onError: (error) => {
    notification.error({
      message: `Request error ${error.errorCode}`,
      description: error.msg,
    });
  },
});

// 2. Plugins
app.use(createLoading());

// 3. Register global model
app.model(globalModel);

// 4. Router
app.router(router);

// 5. Start
app.start('#root');

// 6. WebSocket
const {
  _store: { dispatch },
} = app;
WebSocket.create(dispatch);

FastClick.attach(document.body);

export default app._store;  // eslint-disable-line
