import moment from 'moment';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - (day * oneDay);

    return [moment(beginTime), moment(beginTime + ((7 * oneDay) - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`), moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000)];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟'],
  ];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}


function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!');  // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    let isAdd = false;
    // 是否包含
    isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(routePath =>
    routePath.indexOf(path) === 0 && routePath !== path);
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
      exact,
    };
  });
  return renderRoutes;
}


/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}

export const assembleGoogleAddressFromformAddressResult = (formAddressResult) => {
  let googleAddress = '';

  if (formAddressResult.street_address) {
    googleAddress = googleAddress.concat(`${formAddressResult.street_address}`);
  }

  if (formAddressResult.city) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.city}`);
  }

  if (formAddressResult.state) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.state}`);
  }

  if (formAddressResult.country) {
    const comma = `${googleAddress.length > 0 ? ',' : ''}`;
    googleAddress = googleAddress.concat(`${comma} ${formAddressResult.country}`);
  }

  return googleAddress;
};

export const formatGoogleMapPlace = (googleMapPlace) => {
  const { address_components: addressComponents } = googleMapPlace;

  const googleResult = {
    postalCode: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
    // street number + route equals street_address
    route: undefined,
    // streetNumber: undefined,
  };

  addressComponents.forEach((item) => {
    const { types, short_name: shortName } = item;

    // postal code
    if (types.indexOf('postal_code') !== -1) {
      googleResult.postalCode = shortName;
    }

    // country
    if (types.indexOf('country') !== -1) {
      googleResult.country = shortName;
    }

    // state
    if (types.indexOf('administrative_area_level_1') !== -1) {
      googleResult.state = shortName;
    }

    // city
    if (types.indexOf('locality') !== -1) {
      googleResult.city = shortName;
    }

    // route
    if (types.indexOf('route') !== -1) {
      googleResult.route = shortName;
    }

    // stree_number
    if (types.indexOf('street_number') !== -1) {
      googleResult.streetNumber = shortName;
    }
  });

  const formAddressResult = {
    country: googleResult.country,
    state: googleResult.state,
    city: googleResult.city,
    postal_code: googleResult.postalCode,
    // street_address: ,
  };

  const { route = '' } = googleResult;
  const { streetNumber } = googleResult;

  if (streetNumber && streetNumber.length > 0) {
    formAddressResult.street_address = `${googleResult.streetNumber} ${googleResult.route}`;
  } else {
    formAddressResult.street_address = route;
  }

  return formAddressResult;
};

export const humanReadableDateString = (text) => {
  let result = moment(text).format('MMM D, YYYY');
  const isSameYear = moment().isSame(text, 'year');
  const isSameDay = moment().isSame(text, 'day');
  if (isSameYear) {
    // E.g. Jan 14
    result = moment(text).format('MMM D');
  }
  if (isSameDay) {
    result = `Today ${moment(text).format('h:mm A')}`;
  }
  return result;
};

export const getSubscriptionPriceString = (subscription) => {
  if (!subscription) return '-';
  const { plan } = subscription;
  if (!plan) return '-';
  const currency = plan.currency.toUpperCase();
  const period = plan.interval_count === 1 ? plan.interval :
    `${plan.interval_count} ${plan.interval}`;

  if (plan.plan_type === 'fixed' || plan.plan_type === 'retention') {
    return `$${plan.amount / 100} ${currency} / ${period}`;
  } else if (plan.plan_type === 'location') {
    return `$${plan.amount / 100} ${currency} / location / ${period}`;
  } else if (plan.plan_type === 'location_matrix') {
    const startAmount = Math.max(...plan.tiers.map(tier => tier.unit_amount)) / 100;
    return `Starts at $${startAmount} ${currency} / location / ${period}`;
  } else if (plan.plan_type === 'position') {
    return `$${plan.amount / 100} ${currency} / position / ${period}`;
  } else if (plan.plan_type === 'fixed_locations') {
    return `$${plan.amount / 100} ${currency} / location / ${period}`;
  }

  return '-';
};

export const getSubscriptionAmountString = (subscription) => {
  if (!subscription) return '-';

  const { plan } = subscription;
  if (!plan) return '-';

  const currency = plan.currency.toUpperCase();
  const period = plan.interval_count === 1 ? plan.interval :
    `${plan.interval_count} ${plan.interval}`;

  if (plan.plan_type === 'location_matrix') {
    const sortedTiers = plan.tiers.sort((t1, t2) =>
      (t1.up_to < t2.up_to ? -1 : t1.up_to > t2.up_to ? 1 : 0)
    );

    const units = subscription.quantity;
    let currentTier;
    for (const tier of sortedTiers) {
      currentTier = tier;
      if (tier.up_to === 'inf' || units <= tier.up_to) break;
    }

    const amount = currentTier.unit_amount * units;
    return `$${amount / 100} ${currency} / ${period} (${units} location${units > 1 ? 's' : ''})`;
  } else if (plan.plan_type === 'fixed_locations') {
    const units = subscription.quantity;

    // return `$${plan.amount / 100} ${currency} / location / ${period}`;
    const amount = plan.amount * units;
    return `$${amount / 100} ${currency} / ${period} (${units} location${units > 1 ? 's' : ''})`;
  }

  return '-';
};

export const getSubscriptionPlanAmountString = (subscription) => {
  if (!subscription) return '-';
  const { plan } = subscription;
  if (!plan) return '-';

  const currency = plan.currency.toUpperCase();
  const period = plan.interval_count === 1 ? plan.interval :
    `${plan.interval_count} ${plan.interval}`;

  const planType = plan.plan_type;
  const amountText = (subscription.contract_amount || plan.amount) / 100;
  const intervalText = subscription.contract_interval || period;

  if (planType === 'retention') {
    return `$${plan.amount / 100} ${currency} / ${period}`;
  } else if (planType === 'location_matrix') {
    const startAmount = Math.max(...plan.tiers.map(tier => tier.unit_amount)) / 100;
    return `Starts at $${startAmount} ${currency} / location / ${period}`;
  } else if (planType === 'location') {
    return `$${plan.amount / 100} ${currency} / location / ${period}`;
  } else if (planType === 'position') {
    return `$${plan.amount / 100} ${currency} / position / ${period}`;
  } else if (planType === 'fixed_locations') {
    return `$${amountText} ${currency} / ${intervalText} (Per location)`;
  } else if (planType === 'fixed') {
    return `$${amountText} ${currency} / ${intervalText}`;
  }

  return '-';
};

export const getSubscriptionBilledString = (subscription) => {
  if (!subscription) return '-';
  const { plan, quantity = 1 } = subscription;
  if (!plan) return '-';

  const { interval_count: intervalCount, interval } = plan;

  let billedIntervalLabel = '';
  if (interval === 'month' && intervalCount === 1) {
    billedIntervalLabel = 'Monthly';
  } else if (interval === 'month' && intervalCount === 3) {
    billedIntervalLabel = 'Quarterly';
  } else if (interval === 'month' && intervalCount === 6) {
    billedIntervalLabel = 'Biannually';
  } else if (interval === 'year' && intervalCount === 1) {
    billedIntervalLabel = 'Annually';
  } else {
    billedIntervalLabel = `${intervalCount} ${intervalCount > 1 ? `${interval}s` : interval}`;
  }

  const billedPrice = `$${(plan.amount * quantity) / 100} ${plan.currency.toUpperCase()}`;
  const billedIntervalPeriod = intervalCount === 1 ? interval : `every ${intervalCount} ${interval}s`;

  return `${billedIntervalLabel} - ${billedPrice} / ${billedIntervalPeriod}`;
};

export const getSubscriptionTotalAmountString = (subscription) => {
  return getSubscriptionAmountString(subscription);
};

// BEPlanType: backend plan type
// FEPlanType: frontend plan type
export const converBEPlanTypeToFEPlanType = (BEPlanType) => {
  let FEPlanType = '';
  switch (BEPlanType) {
    case 'position':
    case 'location':
      FEPlanType = 'usage';
      break;
    case 'fixed':
      FEPlanType = 'fixed';
      break;
    case 'fixed_locations':
      FEPlanType = 'capped';
      break;
    default:
  }
  return FEPlanType;
};

export const converFEPlanTypeToFEPlanTypeText = (BEPlanType) => {
  const FEPlanType = converBEPlanTypeToFEPlanType(BEPlanType);
  let FEPlanTypeText = '';
  switch (FEPlanType) {
    case 'usage':
      FEPlanTypeText = 'Usage';
      break;
    case 'capped':
      FEPlanTypeText = 'Capped';
      break;
    case 'fixed':
      FEPlanTypeText = 'Fixed price';
      break;
    default:
  }
  return FEPlanTypeText;
};

export const generateAddPlanParams = (fields, customer, products = []) => {
  // Map FE plan type to BE plan type
  let planType = '';
  switch (fields.plan_type) {
    case 'fixed':
      planType = 'fixed';
      break;
    case 'usage':
      if (fields.billed_by === 'position') {
        planType = 'position';
      } else if (fields.billed_by === 'location') {
        planType = 'location';
      }
      break;
    case 'capped':
      if (fields.billed_by === 'location') {
        planType = 'fixed_locations';
      }
      break;
    default:
  }

  // eslint-disable-next-line
  fields.plan_type = planType;
  // eslint-disable-next-line
  delete fields.billed_by;

  const {
    no_trial: noTrial,
    trial_end: trialEnd,
    retention,
    retention_amount: retentionAmount,
    retention_interval: retentionInterval,
    unit_amount: unitAmount,
    up_to: upTo,
    keys,
    ...planFields
  } = fields;

  planFields.product_id = products.find(product => product.product_type === fields.plan_type).id;
  planFields.customer_id = customer.id;
  if (!noTrial && noTrial !== undefined) {
    const momentTrialEnd = moment(trialEnd);
    const newTrialEnd = moment();
    newTrialEnd.set('year', momentTrialEnd.get('year'));
    newTrialEnd.set('month', momentTrialEnd.get('month'));
    newTrialEnd.set('date', momentTrialEnd.get('date'));
    planFields.trial_end = newTrialEnd;
  }

  if (planFields.plan_type === 'location_matrix') {
    const tiers = keys.map(k => ({
      up_to: upTo[k],
      unit_amount: unitAmount[k],
    }));
    planFields.tiers = tiers;
  } else if (planFields.plan_type === 'fixed') {
    planFields.quantity = 1;
  }

  const plans = [planFields];
  if (retention) {
    const retentionPlan = {
      plan_type: 'retention',
      amount: retentionAmount,
      currency: planFields.currency,
      interval: retentionInterval,
      billing_scheme: 'per_unit',
      quantity: 1,
      product_id: products.find(product => product.product_type === 'retention').id,
      customer_id: customer.id,
    };

    plans.push(retentionPlan);
  }

  return { plans };
};

export function toFormData(obj, form, namespace) {
  const fd = form || new FormData();
  let formKey;

  for (const property in obj) {
    // pass param when the value is null
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property) && obj[property] !== undefined) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (obj[property] instanceof Array) {
        // fd.append(formKey, obj[property]);
        if (obj[property].length === 0) {
          // fd.append(`${formKey}[]`, []);
          // fd.append(formKey, []);
        } else {
          // eslint-disable-next-line no-loop-func
          obj[property].forEach((value) => {
            if (typeof value === 'object') {
              toFormData(value, fd, `${formKey}[]`);
            } else {
              fd.append(`${formKey}[]`, value);
            }
          });
        }
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof window.File)) {
        toFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        // eslint-disable-next-line no-lonely-if
        if (/^\s*$/.test(obj[property])) {
          fd.append(formKey, '');
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
}
