import request from '../utils/request';

export async function fetchLabels(payload) {
  return request('GET', '/admin/franchise_labels', payload);
}

export async function createLabel(payload) {
  return request('POST', '/admin/franchise_labels', payload);
}

export async function deleteLabel(payload) {
  const { id } = payload;
  const path = '/admin/franchise_labels/:id'.replace(':id', id);
  return request('DELETE', path);
}

