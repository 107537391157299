import { notification } from 'antd';
import * as Announcement from '../services/announcement';

export default {
  namespace: 'announcementList',

  state: {
    list: [],
  },
  reducers: {
    querySuccess(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    createSuccess(state, { payload }) {
      const { list } = state;
      return {
        ...state,
        list: [
          payload,
          ...list,
        ],
      };
    },
    updateSuccess(state, { payload }) {
      const { list } = state;
      const newList = list.map(item =>
        (item.id === payload.id ? payload : item)
      );
      return {
        ...state,
        list: newList,
      };
    },
    destroySuccess(state, { payload }) {
      const { list } = state;
      const newList = list.filter(item => item.id !== payload.id);
      return {
        ...state,
        list: newList,
      };
    },
  },
  effects: {
    *query({ payload }, { call, put }) {
      const res = yield call(Announcement.query, payload);
      if (res.error) {
        notification.error({
          message: `Request error ${res.error.errorCode}`,
          description: res.error.msg,
        });
        return;
      }
      yield put({ type: 'querySuccess', payload: res.announcements });
    },
    *create({ payload }, { call, put }) {
      const res = yield call(Announcement.create, payload);
      if (res.error) {
        notification.error({
          message: `Request error ${res.error.errorCode}`,
          description: res.error.msg,
        });
        return;
      }
      yield put({ type: 'createSuccess', payload: res.announcement });
      payload.onComplete();
    },
    *update({ payload }, { call, put }) {
      const res = yield call(Announcement.update, payload);
      if (res.error) {
        notification.error({
          message: `Request error ${res.error.errorCode}`,
          description: res.error.msg,
        });
        return;
      }
      yield put({ type: 'updateSuccess', payload: res.announcement });
      payload.onComplete();
    },
    *destroy({ payload }, { call, put }) {
      const res = yield call(Announcement.destroy, payload);
      if (res.error) {
        notification.error({
          message: `Request error ${res.error.errorCode}`,
          description: res.error.msg,
        });
        return;
      }
      yield put({ type: 'destroySuccess', payload: res });
    },
  },
};
