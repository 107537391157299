import request from '../utils/request';
import { toFormData } from '../utils/utils';

export async function queryAllProducts(params) {
  return request('GET', '/admin/products', params);
}

export async function queryAll(params) {
  return request('GET', '/admin/customers', params);
}

export async function query(params) {
  const { id } = params;
  return request('GET', `/admin/customers/${id}`);
}

export async function addCustomer(params) {
  const data = toFormData(params);
  return request(
    'POST',
    '/admin/customers',
    data,
  );
}

export async function update(params) {
  const { id, ...payload } = params;
  return request('PUT', `/admin/customers/${id}`, payload);
}

export async function addPlan(params) {
  return request('POST', '/admin/plans/create_multiple', params);
}

export async function updateSubscription(params) {
  const { id, ...payload } = params;
  return request('PUT', `/admin/subscriptions/${id}`, payload);
}

export async function cancelSubscription(params) {
  const { id, ...payload } = params;
  return request('DELETE', `/admin/subscriptions/${id}`, { ...payload });
}

export async function updatePayment(params) {
  const { customerId, id, ...payload } = params;
  return request('PUT', `/admin/customers/${customerId}/sources/${id}`, payload);
}

export async function refundCharge(params) {
  const { id, ...payload } = params;
  return request('POST', `/admin/charges/${id}/refund`, payload);
}

export async function fetchChargeRefunds(params) {
  const { id, ...payload } = params;
  return request('GET', `/admin/charges/${id}/refunds`, payload);
}

export async function fetchSubscriptionHistory(params) {
  const { id, ...payload } = params;
  const path = '/admin/customers/:id/subscription_events'.replace(':id', id);
  return request('GET', path, payload);
}

export async function createCharge(params) {
  const { id, ...payload } = params;
  return request('POST', `/admin/customers/${id}/charge`, payload);
}

export async function requestPayment(params) {
  const { id } = params;
  return request('POST', `/admin/customers/${id}/request_payment`);
}

export async function toggleRetention(params) {
  const { id } = params;
  return request('POST', `/admin/customers/${id}/toggle_retention`);
}

export async function verifyBankAccount(params) {
  const { customerId, id, ...payload } = params;
  return request('POST', `/admin/customers/${customerId}/sources/${id}/verify_bank`, payload);
}

export async function sendVerifyLink(params) {
  const { customerId, id } = params;
  return request('POST', `/admin/customers/${customerId}/sources/${id}/send_verify_bank`);
}

export async function payInvoice(params) {
  const { id } = params;
  return request('POST', `/admin/invoices/${id}/pay`);
}

export async function createAttachment(params) {
  const { customer_id: customerId, customer_attachment: customerAttachment = {} } = params;
  const { file } = customerAttachment;
  const data = new FormData();
  if (customerId) data.set('customer_id', customerId);
  if (file) data.set('customer_attachment[file]', file);
  return request('POST', `/admin/customers/${customerId}/customer_attachments`, data);
}

export async function destroyAttachment(params) {
  const { customer_id: customerId, id } = params;
  return request('DELETE', `/admin/customers/${customerId}/customer_attachments/${id}`);
}

export async function bindCompany(params) {
  const { customer_id: customerId, company_id: companyId } = params;
  const data = { company_id: companyId };
  return request('POST', `/admin/customers/${customerId}/bind_company`, data);
}

export async function unbindCompany(params) {
  const { customer_id: customerId, company_id: companyId } = params;
  const data = { company_id: companyId };
  return request('POST', `/admin/customers/${customerId}/unbind_company`, data);
}

export async function bindMoonclerkCustomer(params) {
  const { customer_id: customerId, moonclerk_customer_id: moonclerkCustomerId } = params;
  const data = { moonclerk_customer_id: moonclerkCustomerId };
  return request('POST', `/admin/customers/${customerId}/bind_moonclerk_customer`, data);
}

export async function unbindMoonclerkCustomer(params) {
  const { customer_id: customerId, moonclerk_customer_id: moonclerkCustomerId } = params;
  const data = { moonclerk_customer_id: moonclerkCustomerId };
  return request('POST', `/admin/customers/${customerId}/unbind_moonclerk_customer`, data);
}

export async function refreshMoonclerkCustomer(params) {
  const { customer_id: customerId, moonclerk_customer_id: moonclerkCustomerId } = params;
  const data = { moonclerk_customer_id: moonclerkCustomerId };
  return request('POST', `/admin/customers/${customerId}/refresh_moonclerk_customer`, data);
}
