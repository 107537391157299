import _ from 'lodash';
import request from '../utils/request';

export async function fetchPoddingCodes(payload) {
  return request('GET', '/admin/account_templates', payload);
}

export async function createPoddingCode(payload) {
  return request('POST', '/admin/account_templates', payload);
}

export async function editPoddingCode(payload) {
  const { codeId, account_template: accountTemplate } = payload;
  const path = '/admin/account_templates/:id'.replace(':id', codeId);
  return request('PUT', path, {
    account_template: accountTemplate,
  });
}

export async function fetchTemplateCompanies() {
  const data = {
    page: 1,
    per_page: 500,
    status: 'template',
  };
  return request('GET', '/admin/companies', data);
}

export async function fetchLocationsOfCompany(payload) {
  const { companyId, showPositions } = payload;
  const path = '/admin/companies/:id/locations'.replace(':id', companyId);
  return request('GET', path, { ...(showPositions && { positions: true }) });
}

export async function createTemplateFromActiveCompany(payload) {
  const path = '/admin/companies/create_template';
  return request('POST', path, payload);
}

