import request from '../utils/request';

const reportsPath = '/admin/admin_reports';

export async function query(params) {
  const { id } = params;
  return request('GET', `${reportsPath}/${id}`);
}

export async function queryAll(params) {
  return request('GET', reportsPath, params);
}

export async function create(params) {
  return request('POST', reportsPath, params);
}

export async function update(params) {
  const { id, ...payload } = params;
  return request('PUT', `${reportsPath}/${id}`, payload);
}

export async function destroy(params) {
  const { id } = params;
  return request('DELETE', `${reportsPath}/${id}`);
}

export async function reorder(params) {
  return request('PUT', `${reportsPath}/reorder`, params);
}
