import { notification } from 'antd';
import { queryAll, addAdminUser, updateAdminUser } from '../services/adminUser';

export default {
  namespace: 'adminUserList',

  state: {
    list: [],
    pagination: {},
  },

  effects: {
    *fetchAll({ payload }, { call, put }) {
      const response = yield call(queryAll, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const list = [];
      for (const adminUser of response.admin_users) {
        list.push(Object.assign({ key: adminUser.id }, adminUser));
      }
      yield put({
        type: 'saveAdminUserList',
        payload: {
          list,
          pagination: {
            total: response.all_count,
            current: response.current_page,
            pageSize: payload.per_page,
          },
        },
      });
    },

    *addAdminUser({ payload }, { call, put }) {
      const response = yield call(addAdminUser, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }
      const adminUser = Object.assign({ key: response.admin_user.id }, response.admin_user);

      yield put({
        type: 'updateAdminUserList',
        payload: adminUser,
      });

      payload.onComplete();
    },

    *editAdminUser({ payload }, { call, put }) {
      const response = yield call(updateAdminUser, payload);
      if (response.error) {
        notification.error({
          message: `Request error ${response.error.errorCode}`,
          description: response.error.msg,
        });
        return;
      }

      const adminUser = Object.assign({ key: response.admin_user.id }, response.admin_user);
      yield put({
        type: 'editExistingAdminUser',
        payload: adminUser,
      });

      payload.onComplete();
    },
  },

  reducers: {
    saveAdminUserList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },

    updateAdminUserList(state, action) {
      const { list: { list, pagination } } = state;
      const { total, current, pageSize } = pagination;

      return {
        ...state,
        list: {
          list: [
            ...list,
            ...action.payload,
          ],
          pagination: {
            total: total + 1,
            current,
            pageSize,
          },
        },
      };
    },

    editExistingAdminUser(state, action) {
      const { list: { list, pagination } } = state;
      const updatedList = list.map((adminUser) => {
        if (adminUser.key === action.payload.key) {
          return action.payload;
        }
        return adminUser;
      });

      return {
        ...state,
        list: {
          list: updatedList,
          pagination,
        },
      };
    },
  },
};
