import _ from 'lodash';
import * as services from '../services/asyncTasks';
import constants from '../utils/constants';

export default {
  namespace: 'asyncTasks',

  state: {
    tasks: [],
    tasksExpanded: false,
    tasksVisible: true,
    pagination: {
      total: 10,
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '25', '50', '100'],
    },
  },

  reducers: {
    querySuccess(state, { payload }) {
      const { tasks } = payload;
      return {
        ...state,
        tasks,
      };
    },
    getSuccess(state, { payload }) {
      const { tasks } = state;
      const { task } = payload;
      const newTasks = tasks.map((stateTask) => {
        const { expanded: stateTaskExpanded = false } = stateTask;
        return stateTask.id === task.id ? { ...task, expanded: stateTaskExpanded } : stateTask;
      });

      return {
        ...state,
        tasks: newTasks,
      };
    },
    querySubTasksSuccess(state, { payload }) {
      const { tasks } = state;
      const { id, subTasks } = payload;
      const newTasks = tasks.map((task) => {
        return task.id === id ? { ...task, subTasks } : task;
      });

      return {
        ...state,
        tasks: newTasks,
      };
    },
    expandTaskSuccess(state, { payload }) {
      const { tasks } = state;
      const { id, subTasks } = payload;
      const newTasks = tasks.map((task) => {
        let newTask = task;
        if (task.id === id) {
          newTask = subTasks ? { ...task, subTasks, expanded: true } : { ...task, expanded: true };
        }
        return newTask;
      });

      return {
        ...state,
        tasks: newTasks,
      };
    },
    collapseTaskSuccess(state, { payload }) {
      const { tasks } = state;
      const { id } = payload;
      const newTasks = tasks.map((task) => {
        return task.id === id ? { ...task, expanded: false } : task;
      });

      return {
        ...state,
        tasks: newTasks,
      };
    },
    receiveSuccess(state, { payload }) {
      const { tasks: stateTasks } = state;
      const { task } = payload;
      const selectedTasks = _.filter(stateTasks, stateTask => stateTask.id === task.id);
      let newTasks = [];
      if (selectedTasks.length <= 0) {
        const { tasks: subTasks = [], ...otherTaskArgs } = task;
        const newTask = { ...otherTaskArgs, subTasks: subTasks || [] };
        newTasks = [newTask, ...stateTasks];
      } else {
        newTasks = stateTasks.map((stateTask) => {
          if (stateTask.id !== task.id) return stateTask;
          const { expanded: stateTaskExpanded = false } = stateTask;
          const { tasks: subTasks = [], ...otherTaskArgs } = task;
          return { ...otherTaskArgs, expanded: stateTaskExpanded, subTasks: subTasks || [] };
        });
      }

      return {
        ...state,
        tasks: newTasks,
      };
    },
    receiveTasksSuccess(state, { payload }) {
      const { tasks: stateTasks } = state;
      const { tasks } = payload;
      let newTasks = [...stateTasks];
      tasks.forEach((task) => {
        const selectedTask = _.find(newTasks, stateTask => stateTask.id === task.id);

        if (selectedTask) {
          newTasks = newTasks.map((stateTask) => {
            if (stateTask.id !== task.id) return stateTask;
            const { expanded: stateTaskExpanded = false } = stateTask;
            const { tasks: subTasks = [], ...otherTaskArgs } = task;
            return { ...otherTaskArgs, expanded: stateTaskExpanded, subTasks: subTasks || [] };
          });
        } else {
          const { tasks: subTasks = [], ...otherTaskArgs } = task;
          const newTask = { ...otherTaskArgs, subTasks: subTasks || [] };
          newTasks = [newTask, ...newTasks];
        }
      });

      return {
        ...state,
        tasks: newTasks,
      };
    },
    expandTasksSuccess(state) {
      return {
        ...state,
        tasksExpanded: true,
      };
    },
    collapseTasksSuccess(state) {
      return {
        ...state,
        tasksExpanded: false,
      };
    },
    showTasks(state) {
      return {
        ...state,
        tasksVisible: true,
      };
    },
    hideTasks(state) {
      return {
        ...state,
        tasksVisible: false,
      };
    },
  },

  effects: {
    *query({ payload }, { call, put }) {
      const response = yield call(services.query, payload);
      if (response.error) throw response.error;

      const { async_tasks_batches: tasks } = response;
      yield put({
        type: 'querySuccess',
        payload: { tasks },
      });

      return response;
    },
    *get({ payload }, { call, put }) {
      const response = yield call(services.get, payload);
      if (response.error) throw response.error;

      const { async_tasks_batch: task } = response;
      yield put({
        type: 'getSuccess',
        payload: { task },
      });

      return response;
    },
    *querySubTasks({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(services.querySubTasks, payload);
      if (response.error) throw response.error;

      const { tasks: subTasks } = response;
      yield put({
        type: 'querySubTasksSuccess',
        payload: { id, subTasks },
      });

      return response;
    },
    *bulkMoveApplicantsToNextStage({ payload }, { call }) {
      const response = yield call(services.create, payload);
      if (response.error) throw response.error;
      return response;
    },
    *receive({ payload }, { put }) {
      const { task } = payload;
      yield put({
        type: 'receiveSuccess',
        payload: { task },
      });
    },
    *receiveTasks({ payload }, { put }) {
      const { tasks } = payload;
      yield put({
        type: 'receiveTasksSuccess',
        payload: { tasks },
      });
    },
    *expandTask({ payload }, { call, put }) {
      const { id } = payload;
      yield put({ type: 'expandTaskSuccess', payload: { id } });

      const response = yield call(services.querySubTasks, payload);
      if (response.error) throw response.error;

      const { tasks: subTasks } = response;
      yield put({
        type: 'expandTaskSuccess',
        payload: { id, subTasks },
      });
      return payload;
    },
    *collapseTask({ payload }, { put }) {
      const { id } = payload;
      yield put({
        type: 'collapseTaskSuccess',
        payload: { id },
      });
      return payload;
    },
    *expandTasks({ payload }, { put }) {
      yield put({ type: 'showTasks' });
      return yield put({ type: 'expandTasksSuccess' });
    },
    *collapseTasks({ payload }, { put }) {
      yield put({ type: 'showTasks' });
      return yield put({ type: 'collapseTasksSuccess' });
    },
  },

  subscriptions: {},
};
